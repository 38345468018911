import AWS from 'aws-sdk/dist/aws-sdk-react-native';

export const toBase64 = (dataURI) => {
    const base64String = dataURI.split(',')[1];
    const decoded = atob(base64String);
    const bytes = new Uint8Array(decoded.length);
    for (let i = 0; i < decoded.length; i++) {
      bytes[i] = decoded.charCodeAt(i);
    }
    let binary = '';
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = btoa(binary);
    return base64;
  };

  export const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }

  export const S3Upload = (id, imageData, request_id, _callback) => {

    var albumBucketName = "dcamsv3";
    var bucketRegion = "us-east-2";
    var IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b";
    var albumName = "upload";
  
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
  
    if(id === "passport"){
      id = "front";
    }
  
    var name = "dl_" + id;
    var fileName = request_id + "_" + name + ".jpeg";
  
    var albumPhotosKey = encodeURIComponent(albumName) + "/";
  
    var photoKey = albumPhotosKey + fileName;

    var file_blob = dataURItoBlob(imageData);
  
    // Use S3 ManagedUpload class as it supports multipart uploads
    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file_blob,
        ACL: ""
      }
    });
  
    var promise = upload.promise();
  
    promise.then(
      function(data) {
  
        console.log('here is the data from upload', data); 
  
        _callback( );
  
      },
      function(err) {
        return alert("There was an error uploading your photo: ", err.message);
      }
    );
  
  }

  export const verifyPostEstimate = (data, settings, history ) => {

    //history.push('./verificationprocessing');
    const request_id = settings.request_id;
    data.request_id = request_id;
    data.country = settings.country;
    if(!("front_ext" in data)){
      data.front_ext = "jpeg";
      data.back_ext = "jpeg";
    }
    fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/estimate/process.php",
  {
      method: "POST",
      body: JSON.stringify(data)
  })
  .then(function(res){ return res.json(); })
  .then(function(data){ return true })
  .catch(function() { history.replace(process.env.PUBLIC_URL + '/error'); })
  }

  export async function fetchData( body, url ) {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body)
    });
    const data = await response.json();
    return data;
  }