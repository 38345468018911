import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { verifyPost, nextPath } from "../../../Utils.js";

import "../../../App.css";

class Verify extends Component {
  render() {
    const {
      verifyDisabled,
      data,
      settings,
      history,
      type,
      iWantToPassport,
      iWantToDL,
      iWanttoDisabled,
    } = this.props;
    const selfie_from_register = settings.selfie;

    const isPassportOption = settings.isPassportOption;

    let secondaryButtonText;
    secondaryButtonText =
      settings.text.upload.secondaryButton +
      " " +
      (type === "dl"
        ? settings.text.upload.passport
        : settings.text.upload.id) +
      ". " +
      settings.text.upload.anchorText;

    if (settings.language === "cs" && type === "dl") {
      secondaryButtonText = "Chci nahrát cestovni pas. Klikni sem.";
    }
    if (settings.language === "cs" && type === "passport") {
      secondaryButtonText = "Chci poskytnout jiný doklad. Klikni sem.";
    }

    if (settings.language === "es" && type === "dl") {
      secondaryButtonText =
        "Para subir una foto de un pasaporte, da clic aquí.";
    }
    if (settings.language === "es" && type === "passport") {
      secondaryButtonText = "Para subir una foto de una INE, da clic aquí.";
    }

    if (settings.language === "pt" && type === "dl") {
      secondaryButtonText = "Para carregar um passaporte, clique aqui.";
    }
    if (settings.language === "pt" && type === "passport") {
      secondaryButtonText =
        "Para carregar outro documento de identificação, clique aqui.";
    }

    if (settings.language === "fi" && type === "dl") {
      secondaryButtonText = "Haluatko ladata kuvan passista? Klikkaa tästä.";
    }

    if (settings.language === "fi" && type === "passport") {
      secondaryButtonText =
        "Haluatko ladata kuvan henkilöllisyystodistuksesta? Klikkaa tästä.";
    }

    if (settings.language === "sv" && type === "dl") {
      secondaryButtonText =
        "Vill du ladda upp ett foto av ditt pass? Klicka här.";
    }

    if (settings.language === "sv" && type === "passport") {
      secondaryButtonText =
        "Vill du ladda upp ett foto av ditt ID-kort? Klicka här.";
    }

    if (settings.language === "el" && type === "dl") {
      secondaryButtonText = "Θέλεις να ανεβάσεις διαβατήριο; Κάνε κλικ εδώ.";
    }

    if (settings.language === "el" && type === "passport") {
      secondaryButtonText =
        "Θέλεις να ανεβάσεις ταυτότητα ή δίπλωμα οδήγησης; Κάνε κλικ εδώ.";
    }

    return (
      <div>
        <Row className="mt-5">
          <Col>
            {type === "selfie" && (
              <div>
                <button
                  type="button"
                  className="veratad-primary"
                  disabled={verifyDisabled}
                  onClick={() => verifyPost(data, settings, history)}
                >
                  {settings.text.upload.actionButton}
                </button>
              </div>
            )}

            {selfie_from_register && type !== "selfie" && (
              <div>
                <button
                  type="button"
                  className="veratad-primary"
                  disabled={verifyDisabled}
                  onClick={() => nextPath(history, "../../snap/selfie")}
                >
                  Provide My Selfie
                </button>
              </div>
            )}

            {!selfie_from_register && (
              <div>
                <button
                  type="button"
                  className="veratad-primary"
                  disabled={verifyDisabled}
                  onClick={() => verifyPost(data, settings, history)}
                >
                  {settings.text.upload.actionButton}
                </button>
              </div>
            )}
          </Col>
        </Row>

        {type === "dl" && isPassportOption && (
          <div>
            <Row className="text-center mt-4">
              <Col>
                <button
                  type="button"
                  className="no-style-button small text-muted"
                  style={{ cursor: "pointer" }}
                  disabled={iWanttoDisabled}
                  onClick={iWantToPassport}
                >
                  {secondaryButtonText}
                </button>
              </Col>
            </Row>
          </div>
        )}

        {type === "passport" && (
          <div>
            <Row className="text-center mt-4">
              <Col>
                <button
                  type="button"
                  className="no-style-button small text-muted"
                  style={{ cursor: "pointer" }}
                  disabled={iWanttoDisabled}
                  onClick={iWantToDL}
                >
                  {secondaryButtonText}
                </button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default Verify;
