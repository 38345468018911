import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import faceLoading from '../assets/face-id-loading.json';
import '../../App.css'; 

function Loading(props) {

  const { text } = props;
  
  return (
    <div className="content">
      <Container >
        <Row >
          <Col>
            <Row className="text-center">
              <Col>
                <Lottie
                  animationData={faceLoading}
                  loop
                  play
                  style={{ height: 350 }}
                />
                <p className="text-center">{text}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Loading;
