import {S3Upload, verifyPost} from '../Utils.js'

export const dataURItoBlob = (dataURI) => {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const loadScripts = (sources, state, type, callback) => {

    return new Promise((resolve, reject) => {
      sources.forEach(src => {
          var script = document.createElement('script');
          script.src = src;
          script.async = false;
          document.body.appendChild( script );
          script.onload = function(){
            if(src === '../capture.js'){
              resolve();
            }
          };
      });
    });
}

export const loadScanningScripts = ( state, settings, data, history, type ) => {

  loadScripts(['../cv.js', '../VDDocument.js', '../capture.js'], state, type).then(response => {

    if(typeof makeVDDocumentWidget === 'undefined'){
      if(type === "passport"){
        history.push('/upload/passport/front');
      }else{
        history.push('/upload/dl/front');
      }

    }

    const request_id = settings.request_id;

    function get_VDDocument_cameraFailure(){
      if(type === "passport"){
        history.push('/upload/passport/front');
      }else{
        history.push('/upload/dl/front');
      }
    }

    function get_VDDocument_cameraStarted(){
      state({isLoading:false});
    }

    var mounted = false;
    function get_VDDocument_mounted(e) {
      mounted = true;
    }

    setTimeout(() => checkMounted(mounted), 5000);

    function checkMounted(mounted){
      if(!mounted){
        get_VDDocument_mountFailure();
      }
    }

    function get_VDDocument_mountFailure() {
      if(type === "passport"){
        history.push('/upload/passport/front');
      }else{
        history.push('/upload/dl/front');
      }
    }

    let front_uploaded;

    function get_VDDocument_obverseDetection(e) {
      var file_front = dataURItoBlob(e.detail);
      if(type === "passport"){
        state({isLoading:true});
      }
      S3Upload("front", state, file_front, request_id, function() {
        front_uploaded = true;
        if(type === "passport"){
          state({isLoading:false});
          data.type = "SDK_Scan";
          data.doc_type = "passport";
          verifyPost(data, settings, history);
        }
      });
    }

    function tryagain(){
      state({isLoading:false});
      verifyPost(data, settings, history);
    }

    function get_VDDocument_reverseDetection(e) {
      var file_back = dataURItoBlob(e.detail);
      state({isLoading:true, showTarget: false});
      S3Upload("back", state, file_back, request_id, function() {
        state({isLoading:false, showTarget: true});
        data.type = "SDK_Scan";
        data.doc_type = "dl";
        if(front_uploaded){
          verifyPost(data, settings, history);
        }else{
          setTimeout(() => tryagain(), 5000);
        }

      });
    }

    document.getElementById("target").addEventListener('VDDocument_cameraFailure', function (e) {
      get_VDDocument_cameraFailure();
    });

    document.getElementById("target").addEventListener('VDDocument_mounted', function (e) {
      get_VDDocument_mounted(e);
    });

    document.getElementById("target").addEventListener('VDDocument_mountFailure', function (e) {
      get_VDDocument_mountFailure();
    });

    document.getElementById("target").addEventListener('VDDocument_cameraStarted', function (e) {
      get_VDDocument_cameraStarted();
    });

    document.getElementById("target").addEventListener('VDDocument_obverseDetection', function (e) {
      get_VDDocument_obverseDetection(e);
    });

    document.getElementById("target").addEventListener('VDDocument_reverseDetection', function (e) {
      get_VDDocument_reverseDetection(e);
    });

  })
  .catch(error => {
    if(type === "passport"){
      history.push('/upload/passport/front');
    }else{
      history.push('/upload/dl/front');
    }
  });

}
