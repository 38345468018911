import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {nextUpload, verifyPost, capitalizeFirstLetter} from '../Utils.js'
import '../App.css';

class Buttons extends Component {

  render() {
    const { history, state, data, settings } = this.props;
    const enhanced = settings.enhanced;
    const side = this.props.props.match.params.side;
    const type = this.props.props.match.params.type;
    data.type = "Direct_Upload";
    data.doc_type = type;

    return (
      <div>
      <Container>
      <Row>
        <Col>
        {state.fileUploaded && state.loadingFile === false &&
          <div>
          <p className="font-weight-bold small">
          If the image is clear click next or verify below
          </p>
          </div>
        }
        {!state.fileUploaded && state.loadingFile === false &&
        <p className="small font-weight-bold">
          Upload a JPG or PNG image that is no larger than 5MB
        </p>
        }
        </Col>
      </Row>
        <Row>

        {state.fileUploaded && side === "front" && type === "dl" && state.loadingFile === false &&
          <Col xs={12}>
          <button
            type="button"
            className="veratad-primary"
            disabled={state.size}
            onClick={() => nextUpload(history, './back', state, 'back') }
          >
          Next
          </button>
          </Col>
        }

        {state.fileUploaded && side === "back" && !enhanced && state.loadingFile === false &&
        <Col xs={12}>
        <button
          type="button"
          className="veratad-primary"
          disabled={state.size}
          onClick={() => verifyPost(data, settings, history) }
        >
        Verify
        </button>
        </Col>
      }

    {state.fileUploaded && type === "passport" && state.loadingFile === false &&
      <Col xs={12}>
      <button
        type="button"
        className="veratad-primary"
        disabled={state.size}
        onClick={() => verifyPost(data, settings, history) }
      >
      Verify
      </button>
      </Col>
    }
    {state.loadingFile === false &&
      <Col xs={12} className="mt-3">
        <label htmlFor={state.target} className={state.UploadButtonClass}>
          {!state.fileUploaded &&
            <div>
            Upload {capitalizeFirstLetter(side)}
            </div>
          }
          {state.fileUploaded &&
            <p>
            Upload a New Image
            </p>
          }
        </label>
      </Col>
    }
    </Row>
  </Container>
</div>
    );
  }
}

export default Buttons;
