import React, { Component } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './App.css';
import { ReactSVG } from 'react-svg'


class Svg extends Component {


  render() {
    const { src, color, height, width } = this.props;

    return (
    <div>
    <ReactSVG
      beforeInjection={(svg) => {
        svg.setAttribute('style', 'fill:'  + color + '; height:' + height + '; width:' + width)
      }}
      src={src}
    />
    </div>
    );
  }
}

export default Svg;
