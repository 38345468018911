import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {withRouter} from 'react-router-dom';
import Lottie from 'lottie-react-web'
import calendar from '../assets/calendar.json';
import LoadingIndicator from '../LoadingIndicator.js'
import TryAgain from './TryAgain.js'
import {inIframe, close} from '../Utils.js'
import '../App.css';

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      verifying: false,
      //dob: this.changeDateFormat(),
      day: '',
      month: '',
      year: '',
      isTryAgain: false,
      icon: null,
      header: null,
      sub: null,
      verified: false,
    };

    this.stop = this.stop.bind(this)
    this.verify = this.verify.bind(this)
    this.yearChange = this.yearChange.bind(this)
    this.monthChange = this.monthChange.bind(this)
    this.dayChange = this.dayChange.bind(this)

  }

  stop(){
    this.setState({isStopped:true})
  }

  componentDidMount() {
    this.interval = setInterval(() => this.stop(), 3000);
    this.changeDateFormat();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeDateFormat() {

      let dob = this.props.data.dob;
      let year = dob.substring(0, 4);
      let month = dob.substring(4, 6);
      let day =  dob.substring(6, 8);

      this.setState({day: day, month: month, year: year});

 }


 yearChange(e){
   this.setState({year: e.target.value})
 }

 monthChange(e){
   this.setState({month: e.target.value})
 }

 dayChange(e){
   this.setState({day: e.target.value})
 }

 verify(){


   const that = this;

   that.props.data.dob = this.state.year + this.state.month + this.state.day;

   if(that.props.data.dob.length !== 8){
     alert("DOB is a mandatory field")
   }

   that.setState({verifying: true})

   fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/agematch",
 {
     method: "POST",
     body: JSON.stringify(that.props.data)
 })
 .then(function(res){ return res.json(); })
 .then(function(data){
   that.setState({verifying: false})
   if(data.action === "PASS"){
     that.setState({verified: true, header: data.title, sub: data.subtitle, icon: data.icon})
     window.top.postMessage('PASS', '*');
   }else{
     that.setState({isTryAgain: true})
   }
   //that.setState({running: false, verificationComplete: true, response: data, query: data.query, http_code: data.http_code, time: data.time, resource: data.resource});
 })
 .catch(function() { alert("Something Went Wrong! Please try again.")})

}


render() {
  const { data, settings, history } = this.props;
  const { verifying, isTryAgain } = this.state;

      let logo_url;
      if(settings.style){
        logo_url = (settings.style[0].logo_url ? settings.style[0].logo_url : null);
      }else{
        logo_url = null;
      }

      let isiFrame = inIframe();


      return (  <div className="content">



          {!isTryAgain && !this.state.verified &&
            <Container >
            <Row className="text-center">
              <Col>
                <h4  className="font-weight-bold">Age Verification</h4>
              </Col>
            </Row>

            {logo_url &&

                <div style={{minHeight:"200px", height:"200px"}} className="d-flex justify-content-center text-center">

                    <img alt="logo" className="align-self-center" src={logo_url} style={{maxWidth:"200px", maxHeight:"200px"}}/>

                </div>

            }

          {!logo_url &&
            <Row className="text-center mb-0 pb-0">
              <Col>
            <Lottie
              options={{
                  animationData: calendar,
                }}
              width={250}
              ariaRole={"img"}
              isStopped={this.state.isStopped}
              title={"calendar"}
              />
              </Col>
            </Row>
          }

          <Row className="text-center">
            <Col>
            <p className="mb-2 font-weight-bold">Please enter your accurate Date of Birth</p>
            <p className="text-muted small mt-0 pt-0">By providing your correct Date of Birth you are going to have a great chance at being verified instantly. </p>
            </Col>
          </Row>



              <Row className="text-left mt-3">
            <Col>

            <Form.Group controlId="forMonth">
              <Form.Label>Month</Form.Label>
              <Form.Control type="text" placeholder="MM" maxLength="2" value={this.state.month} onChange={this.monthChange}/>
            </Form.Group>


            </Col>


            <Col>

              <Form.Group controlId="forDay">
                <Form.Label>Day</Form.Label>
                <Form.Control type="text" placeholder="DD" maxLength="2" value={this.state.day} onChange={this.dayChange}/>
              </Form.Group>


            </Col>

            <Col>

              <Form.Group controlId="forYear">
                <Form.Label>Year</Form.Label>
                <Form.Control type="text" placeholder="YYYY" maxLength="4" value={this.state.year} onChange={this.yearChange}/>
              </Form.Group>


            </Col>

              </Row>

            <Row className="mt-4">

              <Col className="text-center">
            {!verifying &&
              <button
                type="button"
                value="device"
                className="veratad-primary mb-4"
                //onClick={() => nextPath(history, '../agematch/try-again') }
                onClick={this.verify}
              >
              Verify Me
              </button>
            }

            {verifying &&
              <LoadingIndicator height={50} width={100} />
            }

              </Col>
            </Row>


          </Container>
          }

          {isTryAgain &&
            <TryAgain data={data} settings={settings}/>
          }

          {this.state.verified &&
            <Container className="h-100">
              <Row className="text-center h-100 justify-content-center align-items-center">
                <Col>
            <Row className="text-center">
              <Col>
                <i className={this.state.icon + "result-icon"}></i>
              </Col>
            </Row>

            <Row className="text-center mt-3">
              <Col>
                <h2 className="font-weight-bold">{this.state.header}</h2>
              </Col>
            </Row>

            <Row className="text-center mt-0 mb-3">
              <Col>
                <p>{this.state.sub}</p>
              </Col>
            </Row>

            {isiFrame &&
              <Row className="text-center mt-5">
                <Col>
                      <button
                        type="button"
                        className="veratad-primary w-50"
                        onClick={() => close(history, settings) }
                        >
                        Close
                      </button>
                  </Col>
              </Row>
            }

            </Col>
        </Row>
            </Container>
          }


      </div>
    );
  }
}


export default withRouter (Index);
