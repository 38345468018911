import React, { useEffect, useRef, useState } from "react";
import body from './body.json';

const Roc = ({ onData, onError }) => {
  const videoRef = useRef();
  const [selfie, setSelfie] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("rgba(0, 0, 0, 0.5)");
  const [message, setMessage] = useState("Center Face in Oval");
  const [issues, setIssues] = useState("");
  const [template, setTemplate] = useState({});
  const [cameraReady, setCameraReady] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [continuations, setContinuations] = useState(false);
  const [initial, setIntial] = useState(false);

  const messages = {
    'IMAGE IS BLURRY': "This is too blurry",
    'IMAGE QUALITY IS LOW': "Low q",
    'IMAGE HAS INVALID ELEMENTS': "invalid",
    'IMAGE IS NOT CENTERED': "Put your face somewhere here",
    'IMAGE IS NOT VALID': "not a face"
  };

  const messageKeys = ['IMAGE IS NOT CENTERED', 'IMAGE IS BLURRY', 'IMAGE HAS INVALID ELEMENTS', 'IMAGE QUALITY IS LOW', 'IMAGE IS NOT VALID'];

  useEffect(() => {
    for (const key of messageKeys) {
      if (issues.includes(key)) {
        setMessage(messages[key]);
        return;
      }
    }
  }, [issues, messageKeys, messages]);

  const getVideoStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setCameraReady(true);
      }
    } catch (error) {
      console.error("Error accessing camera: ", error);
    }
  };
  
  useEffect(() => {
    getVideoStream();
  
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  
  useEffect(() => {
    if (cameraReady) {
      setTimeout(captureFrame, 4000);
    }
  }, [cameraReady]);

  useEffect(() => {
    const handleVideoLoaded = () => {
      setVideoLoaded(true);
    };
  
    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleVideoLoaded);
    }
  
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadedmetadata", handleVideoLoaded);
      }
    };
  }, []);
  
  
  const captureFrame = async () => {
    console.log("capture frame ran");
    if (!videoRef.current ) return;

    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
    const base64DataUrl = canvas.toDataURL("image/jpeg", 0.8).split(",")[1];
    setSelfie(base64DataUrl);
  };
  
  
  const sendRequestWithTemplate = async () => {
    setContinuations(true); 
    console.log("send template ran here ", template); 

    if (!template['endpoint']) {
      template['endpoint'] = {};
    }

    template.required_images.selfie.image = selfie;
    template.endpoint = "https://tampa-2.idresponse.com/process/continue";
    console.log("here is the template", template);
    const response = await fetch("https://demo.veratad.app/api/proxy", {
      method: "POST",
      body: JSON.stringify(template),
    });
    const responseData = await response.json();
    const analytics = responseData?.output?.images.selfie.image_analytics;
    const yaw = analytics.yaw; 
    const pitch = analytics.pitch; 
    if (yaw < 0 || pitch < 0 ) {
      setIssues("IMAGE IS NOT CENTERED");
    }
    return responseData?.result?.action;
  };
  

  const sendInital = async () => {
    if (initial) return;
    setIntial(true); 
    console.log("send initial ran");
    try {
      body.service = "ImageAnalytics5.0";
      body.endpoint = "https://tampa-2.idresponse.com/process/comprehensive/gateway";
      body.target.selfie.image = selfie;
      body.target.age = "21+";

      const response = await fetch("https://demo.veratad.app/api/proxy", {
        method: "POST",
        body: JSON.stringify(body),
      });
      let responseData = await response.json();

      if (responseData?.result?.action !== "PASS") {
        setTemplate(responseData.continuations.images.template);
        setContinuations(true); 
      } else {
        setContinuations(false); 
        onData(selfie);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const processTemplate = async () => {
      if (continuations) {
        console.log("continuations is true")
        const action = await sendRequestWithTemplate();
        if (action !== "PASS") {
          captureFrame(); 
        } else {
          setContinuations(false); 
          onData(selfie);
        }
      }
    };
    processTemplate();
  }, [continuations, selfie]);


  useEffect(() => {
    if (!selfie || selfie.length === 0 || initial ) return;
    sendInital();
  }, [selfie]);


  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }}
    >
      <video
        ref={videoRef}
        muted
        autoPlay
        playsInline
        style={{
          position: "absolute",
          zIndex: 1000,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: videoRef.current ? 1 : 0,
          transform: "scaleX(-1)"
        }}
      ></video>
  
      {videoLoaded && (
        <>
          <svg
            style={{
              position: "absolute",
              zIndex: 1001,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
          >
            <defs>
              <mask id="mask">
                <rect width="100%" height="100%" fill="white" />
                <ellipse cx="50%" cy="50%" rx="45%" ry="45%" fill="black" />
              </mask>
            </defs>
  
            <rect
              width="100%"
              height="100%"
              fill="rgba(0, 0, 0, 0.5)"
              mask="url(#mask)"
            />
  
            <ellipse
              cx="50%"
              cy="50%"
              rx="45%"
              ry="45%"
              stroke="white"
              strokeWidth="1"
              strokeLinecap="round"
              strokeDasharray="5,5"
              fill="none"
            />
          </svg>
          <p
            style={{
              position: "fixed",
              zIndex: 1003,
              top: "calc(50% + 25%)",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "white",
              fontSize: "24px",
              backgroundColor: backgroundColor,
              padding: "10px",
              borderRadius: "5px"
            }}
          >
            {message}
          </p>
        </>
      )}
    </div>
  );
  
};

export default Roc;


