import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {nextPath} from '../../Utils.js'
import Container from 'react-bootstrap/Container';
import '../mitekSDK.css';
import Confirmation from './components/Confirmation';
import Loading from '../../Loading';
import {sdkWrapper} from '../sdkWrapper';
import {acceptImage} from '../Utils.js';
import VerificationProcessing from '../../VerificationProcessing';
import '../../App.css';
import Error from './CaptureFailure'; 


class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentScan: "front",
      sdkLoaded: false, 
      error: false
    };

    this.handleResult = this.handleResult.bind(this)
    this.handleException = this.handleException.bind(this)

  }

  getAutoHints(){
    const { settings } = this.props;
    return settings.text.autoHints;
  }


  componentDidMount() {
    const { data } = this.props;
    data.type = "SDK_Scan"
    this.startCaptureSession();
    if(this.props.match.params.side === "back"){
      this.setState({currentScan: "back"});
    }
  }

  handleResult (result) {
    this.setState({sdkLoaded:false})
    const { settings, data } = this.props;
    const request_id = settings.request_id;

    const international = data.international;

    acceptImage(result, this.setState.bind(this), request_id, international, this.state.currentScan);

    this.setState({preview: result.response.imageData});

  };

  handleException (excpt) {
    console.log('misnap error', excpt); 
    this.setState({isLoading: false, sdkLoaded:false})
    const { history } = this.props;
    
    const type = this.props.match.params.type;

    sdkWrapper.stopAuto(); 
    
      //nextPath(history, '/snap/scan/capturefailure')

      this.setState({error: true});
    

  };

    startCaptureSession(){

      this.setState({sdkLoaded:true});
      const { data, settings } = this.props;
      const international = data.international;

      let subject;

      if(!international){

        if(this.props.match.params.type === "dl"){
          subject = (this.props.match.params.side === "front" ? 'DL_FRONT' : 'PDF417_BARCODE');
        }else{
          subject = 'PASSPORT';
        }
      }else{

        if(this.props.match.params.type === "dl"){
        subject = 'DL_FRONT';
        }else{
          subject = 'PASSPORT';
        }
      }


      const autoHints = settings.text.autoHints;


    sdkWrapper.startAuto(subject, autoHints)
        .then( this.handleResult )
        .catch( this.handleException )
        .finally();
    };

  render() {

    const { isLoading, sdkLoaded, error } = this.state;
    const { history, data, settings } = this.props;

    const isSelfie = (this.props.match.params.type === "selfie" ? true : false);

    const type = this.props.match.params.type;
    const side = this.props.match.params.side;

    if(error){
      return (  <Error settings={settings} data={data} currentScan={this.state.currentScan} type={type} frontLoaded={this.state.imageLoadedFront}/> );
    }

    if( isLoading && sdkLoaded ){
      return (  <Loading message={"Please allow camera access"} /> );
    } else if( isLoading && !sdkLoaded ){
      return (  <Loading  /> );
    } else {
      return ( <div className="content">
      <Container>

      {side !== "verificationprocessing" &&
      <Confirmation image_preview={this.state.preview} side={side} type={type} history={history} data={data} settings={settings}/>
      }

      {side === "verificationprocessing" &&
        <VerificationProcessing selfie={isSelfie} data={data} settings={settings} message={"Verification in progress..."}/>
      }

      </Container>
    </div> );
    }
  }
}

export default withRouter (Index);
