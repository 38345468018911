import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import Nav from './Nav';
import { Redirect } from 'react-router-dom'
import { nextPath } from './Utils.js'

import './App.css';

class ErrorManual extends Component {

  renderRedirect = () => {

      return <Redirect to='/errormanual' />

  }

  render() {
    const { message, description, history, settings } = this.props;

    const isManualCapture = settings.isManualCapture;
    return (
      <div>
      <Router>
      {this.renderRedirect()}
      <Nav />
      </Router>
    <div className="content">
      <Container className="h-100 mt-5">

        <Row className="text-center h-100 justify-content-center align-items-center">
          <Col>
      <Row className="text-center">
        <Col>
          <i className="fal fa-exclamation-circle error-icon"></i>
        </Col>
      </Row>

      <Row className="text-center mt-3">
        <Col>
          <h2 className="font-weight-bold">{message}</h2>
        </Col>
      </Row>

      <Row className="text-center mt-0 mb-3">
        <Col>
          <p>{description}</p>
        </Col>
      </Row>
      {!isManualCapture &&
        <Row className="text-center mt-0 mb-3">
          <Col>
        <button
          type="button"
          className="veratad-primary"
          onClick={() => nextPath(history, '/') }
          >
        Start Over
        </button>
        </Col>
      </Row>
      }


      </Col>
    </Row>

      </Container>
    </div>
    </div>
    );
  }
}

export default withRouter (ErrorManual);
