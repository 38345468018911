import React, { 
  useState, 
} from 'react';

import { 
  useParams, 
  withRouter 
} from 'react-router-dom'

import { 
  verifyPost 
} from '../Utils.js'; 

import {
  S3UploadBlink, 
  getBlinkKey,
} from './Utils.js';

import {
  applyPolyfills,
  defineCustomElements
} from '@microblink/blinkid-in-browser-sdk/ui/loader';

import VerificationProcessing from '../VerificationProcessing';
import Loading from '../Loading';
import Error from '../snap/scan/CaptureFailure'; 
import Intro from './components/Intro'; 
import Container from 'react-bootstrap/Container';
import '../App.css';

function App( props ) {

        const { data, settings, history } = props;
        data.type = "SDK_Scan"
        const licenseKey = getBlinkKey(); 
        const [done, setDone] = useState(false);
        const [seconds, setSeconds] = useState(0);
        const [error, setError] = useState(false);
        const [intro, setIntro] = useState(true);
        const [blink, setBlink] = useState(true);
        const [loading, setLoading] = useState(false);
        const global_timeout = 90; 
        const style = { 
            "--mb-component-background": "#172DDC", 
            "--mb-component-border-radius": "10px",
            "--mb-component-font-color": "#ffffff",
            "--mb-component-font-size": "40px",
            "--mb-component-box-shadow": "0px 8px 15px rgba(0, 0, 0, 0.1)",
            "--mb-component-text-align": "center", 
            "--mb-component-button-size": "60px",
        };
        const req_id = data.request_id; 
        const el = React.useRef(null);
        const recognizer = 'BlinkIdMultiSideRecognizer';

  React.useEffect(() => {

   let intervalId; 

    applyPolyfills().then(() => {
      defineCustomElements().then(() => {

        el.current.licenseKey = getBlinkKey(); 
        el.current.recognizers = [ recognizer ];
        el.current.recognitionTimeout = 12000;
        el.current.allowHelloMessage = false; 
        el.current.recognizerOptions = {
          'BlinkIdMultiSideRecognizer': 
              {
                //to do 'maxAllowedMismatchesPerField'
                'returnEncodedFullDocumentImage': true,
                'allowUncertainFrontSideScan': true, 
                'validateResultCharacters': false,
                'fullDocumentImageExtensionFactors': {
                  'upFactor': '0.1',
                  'downFactor': '0.1',
                  'leftFactor': '0.1',
                  'rightFactor': '0.1'
                }
              }
          };
          
          settings.text.blink['camera-feedback-barcode'] = [settings.text.blink['camera-feedback-barcode-1'], settings.text.blink['camera-feedback-barcode-2']]; 
          settings.text.blink['camera-feedback-scan-back'] = [settings.text.blink['camera-feedback-scan-back-1'], settings.text.blink['camera-feedback-scan-back-2']]; 
          settings.text.blink['camera-feedback-scan-front'] = [settings.text.blink['camera-feedback-scan-front-1'], settings.text.blink['camera-feedback-scan-front-2']]; 
          
          el.current.translations = settings.text.blink;
          el.current.showCameraFeedbackBarcodeMessage = true; 
          el.current.scanFromImage = false; 
          el.current.showActionLabels = false; 
          el.current.engineLocation = `${process.env.PUBLIC_URL}/resources/`;
          el.current.workerLocation =  `${process.env.PUBLIC_URL}/resources/BlinkIDWasmSDK.worker.min.js`;
        
          el.current.addEventListener('ready', (ev) => {
            //we may do something here 
          });

          el.current.addEventListener('feedback', (ev) => {
            //we may do something here 
          });

         

          el.current.addEventListener('cameraScanStarted', (ev) => {
          
          setIntro(false); 

              // start the timer 
              const intervalId = setInterval(() => {
              setSeconds(seconds => seconds + 1);
            }, 1000);
          
          });

          el.current.addEventListener('scanSuccess', (ev) => {

            console.log('scanSuccess', ev.detail);
            setBlink(false); 
            setLoading(true); 

              S3UploadBlink("front", ev.detail.recognizer.fullDocumentFrontImage.encodedImage.buffer, req_id, function( ) {
                S3UploadBlink("back", ev.detail.recognizer.fullDocumentBackImage.encodedImage.buffer, req_id, function( ) {
                  verifyPost(data, settings, history)
                  setDone(true); 
                  setLoading(false); 
                });
              });
          });

          el.current.addEventListener('scanError', (ev) => {
            setError(true);
            setBlink(false); 
          });

          el.current.addEventListener('scanAborted', (ev) => {
            setError(true);
            setBlink(false); 
          });

          el.current.addEventListener('fatalError', (ev) => {
            setError(true);
            setBlink(false); 
          });
        });
      });

    return () => {
      clearInterval(intervalId); // Restore the original value
    };

  }, [ data, settings, req_id, history ]);

  if(loading){
    return( <Loading /> ); 
  }


  if(seconds >= global_timeout || error){
    return (  <Error settings={settings} data={data} currentScan={"front"} type={"dl"} frontLoaded={false}/> );
  }

  if(done){
    return (
        <VerificationProcessing selfie={false} data={data} settings={settings} message={"Verification in progress..."}/>
    )
  }

  if(seconds < global_timeout && !error){
    return (
    <div className="mt-5">
        <Container>
          {intro && 
              <Intro settings={settings} data={data} />
          }
          {blink && 
            <div className="mt-5">
            <blinkid-in-browser style={style} ref={el}></blinkid-in-browser>
            </div>
          }
          
          
        </Container>
      </div>
      );
  }

}

export default withRouter (App);