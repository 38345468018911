import React, { useState, useEffect } from 'react';
import Yoti from './App';
import Loading from '../../Loading';

const YotiWrapper = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // You can adjust the timeout value as needed
    return () => clearTimeout(timer);
  }, []);

  if (!isLoaded) {
    return <Loading text={"Getting ready..."} />;
  }

  return (
    <Yoti
      onData={props.onData}
      onError={props.onError}
      isSecure={props.isSecure}
    />
  );
};

export default YotiWrapper;
