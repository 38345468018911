import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import VerificationProcessing from '../VerificationProcessing';
import Loading from '../Loading';
import {loadScanningScripts} from './sdk.js';
import '../App.css';


class Index extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      showTarget: true
    }
  }

  componentDidMount() {
    const { history, data, settings } = this.props;
    const type = this.props.match.params.type;
    data.type = "SDK_Scan";
    loadScanningScripts( this.setState.bind(this), settings, data, history, type );
  }

  render() {

    const { isLoading, showTarget } = this.state;
    const { settings, data } = this.props;
    const type = this.props.match.params.type;
    const review_image = data.autoScanReviewImage;
    return (
        <div>
        {isLoading &&
          <Loading />
        }

      {showTarget &&
        <div data-type={type} data-review={review_image} id="target"></div>
      }

        {type === "verificationprocessing" &&
          <VerificationProcessing settings={settings} message={"Verification in progress..."}/>
        }
        </div>
      );
      }
}

export default withRouter (Index);
