import React, { Component } from "react";
import {
  withRouter,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { nextPath } from "../../Utils.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { sdkWrapper } from "../sdkWrapper";
import { acceptImage } from "../Utils";
import { verifyPost } from "../../Utils";
import "../../App.css";
import Loading from "../../Loading.js";
import VerificationProcessing from "../../VerificationProcessing";
import { Stack } from "react-bootstrap";
import Svg from "../../Svg.js";
import Circle from "../../assets/circle.svg";
import CircleComplete from "../../assets/circle_complete.svg";

class CaptureFailure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoading: false,
      imageLoadingFront: false,
      imageLoadedFront: props.frontLoaded,
      imageLoadingBack: false,
      imageLoadedBack: false,
      imageLoadingPassport: false,
      imageLoadedPassport: false,
      error: false,
      errorTitle: "Error",
      errorSubTitle: "Something went wrong. Please try again.",
      sent: false,
      uploading: false,
      currentSubject: null,
    };

    this.handleResult = this.handleResult.bind(this);
    this.capture = this.capture.bind(this);
  }

  handleResult(result) {
    const { settings } = this.props;

    const request_id = settings.request_id;
    const international = true;
    acceptImage(
      result,
      this.setState.bind(this),
      request_id,
      international,
      this.state.currentSubject
    );

    this.setState({ uploading: false });
  }

  componentDidMount() {
    const { currentScan } = this.props;

    if (currentScan === "back") {
      this.setState({ imageLoadedFront: true });
    }
  }

  componentDidUpdate() {
    const { history, settings, data } = this.props;

    const isSelfie = settings.selfie;

    if (
      this.state.imageLoadedFront &&
      settings.isOneSided &&
      !this.state.sent &&
      !isSelfie
    ) {
      //this.setState({uploading: false})
      console.log("we now have the front and back so lets send it");
      verifyPost(data, settings, history);
      this.setState({ sent: true });
    }

    if (
      this.state.imageLoadedFront &&
      this.state.imageLoadedBack &&
      !this.state.sent &&
      !isSelfie
    ) {
      data.type = "Mobile_Upload";
      verifyPost(data, settings, history);
      this.setState({ sent: true });
    }

    if (this.state.imageLoadedPassport && !this.state.sent && !isSelfie) {
      //this.setState({uploading: false})
      data.type = "Mobile_Upload";
      verifyPost(data, settings, history);
      this.setState({ sent: true });
    }

    if (
      this.state.imageLoadedFront &&
      this.state.imageLoadedBack &&
      !this.state.sent &&
      isSelfie
    ) {
      nextPath(history, "../../selfie");
    }

    if (this.state.imageLoadedPassport && !this.state.sent && isSelfie) {
      nextPath(history, "../../selfie");
    }
  }

  capture(subject) {
    const { type } = this.props;

    const id = type === "dl" ? "DL_FRONT" : "PASSPORT";

    this.setState({ currentSubject: subject, uploading: true });

    sdkWrapper.startManual(id).then(this.handleResult).catch(this.handleResult);
  }

  render() {
    const { settings, data, currentScan, type } = this.props;

    const front_complete = this.state.imageLoadedFront ? true : false;
    const back_complete = this.state.imageLoadedBack ? true : false;
    const passport_complete = this.state.imageLoadedPassport ? true : false;
    const isManual = settings.isManualCapture;
    const text = settings.text.scanManual;

    if (this.state.uploading) {
      return <Loading />;
    }

    if (this.state.sent) {
      return (
        <VerificationProcessing
          selfie={false}
          data={data}
          settings={settings}
          message={"Verification in progress..."}
        />
      );
    }

    if (!isManual) {
      return <Redirect to="/errormanual" />;
    }

    return (
      <div>
        <p
          width="100%"
          height="20px"
          className="p-3 text-left font-weight-bold"
          style={{
            backgroundColor: "rgb(255, 99, 71, .2)",
            border: "1px solid rgb(255, 99, 71)",
            color: "rgb(255, 99, 71)",
          }}
        >
          {text.errorMessage}
        </p>

        <Container className="mt-5">
          <Row className="text-left mt-3">
            <Col>
              <h1 className="font-weight-bold">{text.title}</h1>
            </Col>
          </Row>

          <Row className="text-left mt-0">
            <Col>
              <p className="text-muted">{text.subtitle}</p>
            </Col>
          </Row>
          {type === "passport" && (
            <Row
              onClick={() => {
                this.capture("passport");
              }}
            >
              <Col>
                <Stack
                  //onClick={() => this.handleModalShowHide()}
                  direction="horizontal"
                  gap={2}
                  className="text-left mt-4 p-3"
                  style={{ backgroundColor: "rgba(217, 217, 217, .5)" }}
                >
                  <div
                    className="align-items-center"
                    style={{ height: "50px", display: "flex" }}
                  >
                    <Svg
                      src={
                        front_complete || currentScan === "back"
                          ? CircleComplete
                          : Circle
                      }
                    />
                  </div>
                  <div
                    className="align-items-center"
                    style={{ height: "50px", display: "flex" }}
                  >
                    <div className="pl-2">
                      <p className="font-weight-bold mb-0 pb-0">
                        {text.passportTitle}
                      </p>
                    </div>
                  </div>
                  <div
                    className="ms-auto align-items-center"
                    style={{ height: "50px", display: "flex" }}
                  >
                    {passport_complete && (
                      <p
                        className="p-1 text-center small mt-3 font-weight-bold"
                        style={{
                          width: "100px",
                          backgroundColor: "rgba(0,128,0, .2)",
                          border: "1px solid #31D1DB",
                          color: "#000000",
                        }}
                      >
                        {text.complete}
                      </p>
                    )}
                    {!passport_complete && (
                      <p className="p-1 text-center small mt-3 font-weight-bold border border-dark">
                        {text.action}
                      </p>
                    )}
                  </div>
                </Stack>
              </Col>
            </Row>
          )}

          {type === "dl" && (
            <div>
              <Row
                onClick={() => {
                  this.capture("front");
                }}
              >
                <Col>
                  <Stack
                    //onClick={() => this.handleModalShowHide()}
                    direction="horizontal"
                    gap={2}
                    className="text-left mt-4 p-3"
                    style={{ backgroundColor: "rgba(217, 217, 217, .5)" }}
                  >
                    <div
                      className="align-items-center"
                      style={{ height: "50px", display: "flex" }}
                    >
                      <Svg
                        src={
                          front_complete || currentScan === "back"
                            ? CircleComplete
                            : Circle
                        }
                      />
                    </div>
                    <div
                      className="align-items-center"
                      style={{ height: "50px", display: "flex" }}
                    >
                      <div className="pl-2">
                        <p className="font-weight-bold mb-0 pb-0">
                          {text.frontTitle}
                        </p>
                      </div>
                    </div>
                    <div
                      className="ms-auto align-items-center"
                      style={{ height: "50px", display: "flex" }}
                    >
                      {front_complete && (
                        <p
                          className="p-1 text-center small mt-3 font-weight-bold"
                          style={{
                            width: "100px",
                            backgroundColor: "rgba(0,128,0, .2)",
                            border: "1px solid #31D1DB",
                            color: "#000000",
                          }}
                        >
                          {text.complete}
                        </p>
                      )}
                      {!front_complete && (
                        <p className="p-1 text-center small mt-3 font-weight-bold border border-dark">
                          {text.action}
                        </p>
                      )}
                    </div>
                  </Stack>
                </Col>
              </Row>
              {!settings.isOneSided && (
                <Row
                  onClick={() => {
                    this.capture("back");
                  }}
                >
                  <Col>
                    <Stack
                      //onClick={() => this.handleModalShowHide()}
                      direction="horizontal"
                      gap={2}
                      className="text-left mt-4 p-3"
                      style={{ backgroundColor: "rgba(217, 217, 217, .5)" }}
                    >
                      <div
                        className="align-items-center"
                        style={{ height: "50px", display: "flex" }}
                      >
                        <Svg src={back_complete ? CircleComplete : Circle} />
                      </div>
                      <div
                        className="align-items-center"
                        style={{ height: "50px", display: "flex" }}
                      >
                        <div className="pl-2">
                          <p className="font-weight-bold mb-0 pb-0">
                            {text.backTitle}
                          </p>
                        </div>
                      </div>
                      <div
                        className="ms-auto align-items-center"
                        style={{ height: "50px", display: "flex" }}
                      >
                        {back_complete && (
                          <p
                            className="p-1 text-center small mt-3 font-weight-bold"
                            style={{
                              width: "100px",
                              backgroundColor: "rgba(0,128,0, .2)",
                              border: "1px solid #31D1DB",
                              color: "#000000",
                            }}
                          >
                            {text.complete}
                          </p>
                        )}
                        {!back_complete && (
                          <p className="p-1 text-center small mt-3 font-weight-bold border border-dark">
                            {text.action}
                          </p>
                        )}
                      </div>
                    </Stack>
                  </Col>
                </Row>
              )}
            </div>
          )}

          <Row className="mt-5">
            <Col>
              <p className="text-muted small text-center">{text.subtext}</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(CaptureFailure);
