import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UploadButtons from './Buttons';
import Loading from '../LoadingIndicator';
import UploadImage from '../assets/upload.svg';
import {withRouter} from 'react-router-dom';
import '../App.css';

class Input extends Component {

  render() {
    const { data, history, state, settings } = this.props;
    const side = this.props.match.params.side;
    if(side === "front"){
      data.front_ext = state.ext;
    }else if(side === "back"){
      data.back_ext = state.ext;
    }

    const file_preview = ( state.file  ? state.file : UploadImage );
    const preview = (state.loadingFile ? <Loading height={150} width={150}/> : <img src={file_preview} alt="Upload Example" width={200} height={150} />);

    return (
      <div>
      <Container >
      <Row className="">
        <Col className="text-center shadow-sm border border-light pr-5 pl-5 pt-2 pb-2">
        <div>
        <input
        id={state.target}
        accept="image/jpeg, image/png"
        style={{display:'none'}}
        type={"file"}
        onChange={this.props.handleChange}/>
      </div>
          <div className="mt-3">
        {preview}
          </div>
          <div className="mt-5">
      <Row>
        <Col>
            <UploadButtons state={state} data={data} settings={settings} history={history} props={this.props}/>
        </Col>
      </Row>
        </div>
        </Col>
      </Row>
    </Container>
  </div>
    );
  }
}

export default withRouter (Input);
