import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {cameraGranted} from './Utils.js';
import './App.css';


class Loading extends Component {

  constructor(props){
    super(props)
    this.state = {isCamera: false, isChecking: true}
  }


  componentDidMount(){
    cameraGranted(this.setState.bind(this), function() {});
  }

  render() {
    const { message, message_two } = this.props;
    const { isCamera, isChecking } = this.state;

    return (
    <div className="content">
      <Container className="h-100">
        <Row className="text-center h-100 justify-content-center align-items-center">
          <Col>
          <Loader
              type="ThreeDots"
              color="#00000"
              height="100"
              width="100"
              timeout={100000}
           />
           {!isChecking && !isCamera &&
             <div>
              <p>{message}</p>
              <p>{message_two}</p>
             </div>
           }

          </Col>
        </Row>
      </Container>
    </div>
    );
  }
}

export default Loading;
