import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withRouter} from 'react-router-dom';
import {nextPath} from '../Utils.js'

import '../App.css';
import './MethodSelect.css';

class MethodSelect extends Component {

  render() {
    const { history, data, settings } = this.props;
    const qr = data.qr;
    const path = (qr ? '../qr' : '../email/send');

    const isManualCapture = settings.isManualCapture;

    return (<div>
      <Container>
      <Row className="mt-3">
        <Col>
        <p className="mb-2 font-weight-bold">{settings.text.desktopIntro.subTitle}</p>
        <p className="text-muted small mt-0 pt-0">{isManualCapture ? settings.text.desktopIntro.explanation : 'Please click below to get a link to continue on your phone'}</p>
        </Col>
      </Row>
      <Row className="mt-4">
      {isManualCapture &&
        <Col>
          <button
            type="button"
            value="email"
            className="veratad-secondary mb-4"
            onClick={() => nextPath(history, '../snap/upload/dl') }
            //onClick={() => nextPath(history, '../snap/selfie') }
          >
          {settings.text.desktopIntro.secondaryButton}
          </button>
        </Col>
      }
        <Col>
            <button
              type="button"
              value="device"
              className="veratad-primary mb-4"
              onClick={() => nextPath(history, path) }
            >
            {settings.text.desktopIntro.actionButton}
            </button>
        </Col>
      </Row>

      </Container>
    </div>
    );
  }
}

export default withRouter (MethodSelect);
