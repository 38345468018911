import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Lottie from 'lottie-react-web'
import idScan from '../../assets/id-scan.json';
import IntroModal from '../../snap/scan/v2/components/IntroModal';


function Intro(props) {

    const { settings } = props;

  return (
    <div>
      <Container >

          <IntroModal text={settings.text}/>

          <Row className="text-left mb-0 pb-0">
            <Col>
              <h3 className="font-weight-bold">{settings.text.mobileIntro.title}</h3>
              <p className="small muted">{settings.text.mobileIntro.subTitle}</p>
            </Col>
          </Row>

          <Row className="text-center mb-0 pb-0 mt-0 pt-0">
            <Col>
            <Lottie
              options={{
                animationData: idScan,
              }}
              width={175}
              ariaRole={"img"}
              title={"id-scan"}
            />
            </Col>
          </Row>

          <Row className="text-left mb-0 pb-0 font-weight-bold">
            <Col>
              <p>{settings.text.mobileIntro.explanation}</p>
            </Col>
          </Row>

          <Row className="text-left h6 text-muted pl-3 mt-0 pt-0">
            <Col sm={12} md={4} className="mt-1">
              <p ><i className="fal fa-check "></i><span className="ml-3 ">{settings.text.mobileIntro.explanation_1}</span></p>
            </Col>
            <Col sm={12} md={4} className="mt-1">
              <p ><i className="fal fa-check "></i><span className="ml-3 ">{settings.text.mobileIntro.explanation_2}</span></p>
            </Col>
            <Col sm={12} md={4} className="mt-1">
              <p ><i className="fal fa-check"></i><span className="ml-3 ">{settings.text.mobileIntro.explanation_3}</span></p>
            </Col>
          </Row>

    </Container>
    </div>

  );
}

export default Intro;