import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../App.css";

class Header extends Component {
  render() {
    const { header_class, textShow, type, settings } = this.props;

    let title;
    let subtitle;
    let image_type =
      type === "dl"
        ? settings.text.upload.id
        : type === "passport"
        ? settings.text.upload.passport
        : type === "selfie"
        ? settings.text.upload.selfie
        : "ID";

    title = settings.text.upload.title + " " + image_type;
    subtitle = settings.text.upload.subTitle;

    if (settings.language === "cs" && type === "dl") {
      title = "Nahraj svůj doklad";
    }

    if (settings.language === "cs" && type === "passport") {
      title = "Nahraj svůj Cestovní pas";
    }

    if (settings.language === "pt" && type === "dl") {
      title = "Carregue o seu documento de identificação";
    }

    if (settings.language === "pt" && type === "passport") {
      title = "Carregar Passaporte";
    }

    if (settings.language === "fi" && type === "dl") {
      title = "Lataa kuvallinen henkilöllisyystodistus";
    }

    if (settings.language === "fi" && type === "passport") {
      title = "Lataa kuva passistasi";
    }

    if (settings.language === "sv" && type === "dl") {
      title = "Ladda upp ditt ID kort";
    }

    if (settings.language === "sv" && type === "passport") {
      title = "Ladda upp ett foto av ditt pass";
    }

    if (settings.language === "el" && type === "dl") {
      title = "Ανέβασε την ταυτότητα σου ή το δίπλωμα οδήγησης";
    }

    if (settings.language === "el" && type === "passport") {
      title = "Ανέβασε το Διαβατήριό σου";
    }

    if (settings.language === "en" && settings.site_name === "GAVS-PROD-US") {
      subtitle =
        "Each picture should have a minimum size of 100 KB and a maximum size of 5 MB. Please, ensure that the image is not blurry, and the edges are not cut off.";
    }

    return (
      <div>
        <Row className={header_class}>
          <Col>
            <h4 className="font-weight-bold">{title}</h4>
            <p className="pt-0 text-muted small" style={{ display: textShow }}>
              {type === "selfie"
                ? settings.text.upload.selfieSubTitle
                : settings.text.upload.subTitle}
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Header;
