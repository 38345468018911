import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchData } from '../helpers';

import Loading from './Loading';
import Lottie from 'react-lottie-player';
import faceLoading from '../assets/face-id-loading.json';
import '../../App.css'; 

function Verify(props) {
  const { settings, data } = props;
  const [status, setStatus] = useState('NEW');
  const [statusData, setStatusData] = useState('');

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const body = { token: settings.token, request_id: settings.request_id, email: settings.email, data: data };
        const url = process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/statusCheck";
        const statusData = await fetchData(body, url);
        console.log(statusData);
        setStatusData(statusData); 
        setStatus(statusData.status);
      } catch (error) {
        console.log("Please try again.");
      }
    };

    if (status === "NEW") {
      const interval = setInterval(() => {
        fetchStatus();
      }, 3000);

      

      return () => clearInterval(interval);
    }
  }, [status]);

  if (status !== "NEW") {
    
    if(status === 'PASS'){
      window.top.postMessage('PASS', '*');
    }

    if(status === 'FAIL'){
      window.top.postMessage('FAIL', '*');
    }

    return (
      <div >
      
            <Row className="text-center">
              <Col>
                <i className={statusData.icon + "result-icon"}></i>
              </Col>
            </Row>
  
            <Row className="text-center mt-3">
              <Col>
                <h2 className="font-weight-bold">{statusData.title}</h2>
              </Col>
            </Row>
  
            <Row className="text-center mt-0 mb-3">
              <Col>
                <p>{statusData.subtitle}</p>
              </Col>
            </Row>

        
    </div>
    );
  }
  

  return (
    <Loading text={props.text}/>
  );
}

export default Verify;
