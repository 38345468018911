import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {nextPath} from '../../Utils.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Selfie from '../../assets/selfie.png';
import '../../App.css';

class Index extends Component {


render() {
  const { history, settings } = this.props;



      return (  <div className="content">

          <Container >

          <Row className="text-left">
            <Col>
              <h3 className="font-weight-bold">{settings.text.selfie.title}</h3>
            </Col>
          </Row>

          <Row className="text-center mt-5 pb-0">
            <Col>
            <img src={Selfie} height={150} width={150} alt="Selfie"/>
            </Col>
          </Row>


          <Row className="text-left h6 text-muted mt-2">
          <Col sm={12} md={4} className="mt-3">
            <p ><i className="fal fa-check"></i><span className="ml-3 ">{settings.text.selfie.explanation_1}</span></p>
          </Col>
            <Col sm={12} md={4} className="mt-3">
              <p ><i className="fal fa-check "></i><span className="ml-3 ">{settings.text.selfie.explanation_2}</span></p>
            </Col>
            <Col sm={12} md={4} className="mt-3">
              <p ><i className="fal fa-check "></i><span className="ml-3 ">{settings.text.selfie.explanation_3}</span></p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col >
              <button
                type="button"
                value="email"
                className="veratad-primary mb-4"
                onClick={() => nextPath(history, './selfie/scan/selfie') }
              >
              {settings.text.selfie.actionButton}
              </button>
            </Col>
          </Row>

        </Container>
      </div>
    );
  }
}

export default withRouter (Index);
