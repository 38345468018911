import React, { useState, useEffect } from 'react';
import Intro from './components/Intro';
import Loading from './components/Loading.js'; 
import Verify from './components/Verify.js'; 
import {isMobile} from 'react-device-detect';
import Qr from './components/Qr.js'; 
import YotiWrapper from './components/providers/yoti/YotiWrapper'; 
import Roc from './components/providers/roc/Veratad/App'; 
import { Container, Row, Col } from 'react-bootstrap';
import { 
    //toBase64, 
    S3Upload, 
    verifyPostEstimate
 } from './helpers';
 import './App.css'; 

const urlParams = new URLSearchParams(window.location.search);
const qr = urlParams.get('qr') === 'true';


 // to do 
 // set a timeout for the sdks, 
 // create a clear state so that the user can start over, 
 // have an erro page on error with a "start over button"
 // logic up top for which SDK to load 
 //need to send web or desktop 

const App = ( props ) => {

    const request_id = props.settings.request_id; 
    const [getStarted, setGetStarted] = useState(false);
    const [selfie, setSelfie] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [posted, setPosted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sdkLoaded, setSdkLoaded] = useState(false);
    const [skipQr, setSkipQr] = useState(false);
    const [verifyText, setVerifyText] = useState("Calculating your age...");
    props.data.type = "Yoti_Face_Capture";

    const base64ToDataUri = (base64) => {
      return `data:image/jpeg;base64,${base64}`;
    };
    

    const handleResult = async (result, secure) => {
      if (!result.startsWith("data:image/jpeg;base64,")) {
        result = base64ToDataUri(result);
      }
      console.log(result); 
      if (result) {
        if (!props.data['estimate']) {
          props.data['estimate'] = {};
        }
        if (!props.data['estimate']['yoti']) {
          props.data['estimate']['yoti'] = {};
        }
        if (!secure) {
          secure = {};
        }
        if (!secure['device']) {
          secure['device'] = isMobile ? 'mobile' : 'web';
        }
        props.data['estimate']['yoti']['secure'] = secure;
        setSelfie(result); 
      }
    }

    useEffect(() => {
      
      if (qr) {
        fetch(process.env.REACT_APP_BACKEND_URL + '/v3/stable/api/frontend/qrStatus', {
          method: 'POST',
          body: JSON.stringify({ request_id: props.settings.request_id, type: "set", site_name: props.settings.site_name }),
        })
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.error(error));
      }
    
    }, [qr, request_id]);
    
  useEffect(() => {
    if(selfie && !uploaded){
        S3Upload("selfie", selfie, request_id, function( ) {
            setUploaded(true); 
            verifyPostEstimate(props.data, props.settings, props.history)
            setPosted(true); 
        });
    }
  }, [selfie, uploaded]);

  const handleException = (excpt) => {
    console.log("ERROR", excpt)
  }

  const handleGetStartedClick = (message) => {
    setGetStarted(true);
  };

  const handleContinue = () => {
    setSkipQr(true);
  };

  function handleQrStatusChange(status) {
    if (status) {
      setUploaded(true)
      setSelfie(true)
      setPosted(true)
      setSkipQr(true)
      setGetStarted(true)
      setVerifyText("Waiting for you to complete on your mobile device. Please do not refresh the page.")
    }
  }

 
  const sdk = (props.settings.estimation_provider === 'yoti' 
  ? <YotiWrapper onData={handleResult} onError={handleException} isSecure={false} /> 
  : <Roc onData={handleResult} onError={handleException} />);


  if(!isMobile && !skipQr && props.data.desktopToMobileHandoff?.isActive){
    return( 
      <div className="content">
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
        <Row>
            <Col>
              <Qr settings={props.settings} onContinue={handleContinue} data={props.data} onQrStatusChange={handleQrStatusChange}/> 
            </Col>
          </Row>
        </Container>
    </div>);
  }


  return (
      <div className="content">
        <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
          <Row>
            <Col>
              <div>
                {!getStarted && <Intro onGetStartedClick={handleGetStartedClick} />}
                {getStarted && !selfie && sdk}
                {getStarted && selfie && !posted && <Loading text={"Calculating your age..."} />}
                {uploaded && selfie && posted && <Verify text={verifyText} data={props.data} settings={props.settings} />}
              </div>
            </Col>
          </Row>
        </Container>
    </div>
  );
};

export default App;
