import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withRouter} from 'react-router-dom';
import {nextPath} from '../../../Utils.js';
import '../../../App.css';

class Index extends Component {

  render() {
    const { history, settings, data } = this.props;

    const primary_path = (settings.doc_type === "DL" ? '../scan/scansidestart/dl/front' : '../scan/scansidestart/passport/front');
    const secondary_path = (primary_path === "../scan/scansidestart/dl/front" ? '../scan/scansidestart/passport/front' : '../scan/scansidestart/dl/front');
    data.isDocumentTypeSeen = true;
    return (

      <div className="content">
        <Container className="h-100 mt-5">

          <Row className="text-center h-100 justify-content-center align-items-center">
            <Col>

            <Row className="text-center">
              <Col>
              <i className="fal fa-lightbulb-on result-icon"></i>
              </Col>
            </Row>

            <Row className="text-center mt-3">
              <Col>
                <h2 className="font-weight-bold">{settings.text.documentTypeIntro.title}</h2>
              </Col>
            </Row>

        <Row className="text-center mt-4">
          <Col>
            <p>{settings.text.documentTypeIntro.subtitle}</p>
          </Col>
        </Row>
        <Row className="mt-3">
        <Col className="col-12">
            <button
              type="button"
              className="veratad-primary mb-4"
              onClick={() => nextPath(history, primary_path) }
            >
            {settings.text.documentTypeIntro.primary_button}
            </button>
        </Col>

        <Col className="col-12">
            <button
              type="button"
              className="veratad-secondary mb-4"
              onClick={() => nextPath(history, secondary_path) }
            >
            {settings.text.documentTypeIntro.secondary_button}
            </button>
        </Col>

      </Row>
        </Col>
      </Row>


        </Container>
      </div>
    );
  }

}

export default withRouter (Index);
