import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from '../../../LoadingIndicator.js';
import Svg from '../../../Svg.js';

import '../../../App.css';

class Selfie extends Component {

  render() {

    const { uploadHandleClick, imageLoadingFront, imageLoadedFront, preview_selfie, settings } = this.props;

    const loading_color = settings.style[0].settings.loading;
    const icon_color = settings.style[0].settings.icons;

    const front_image = (imageLoadedFront ? <img src={preview_selfie} alt="Upload Example Front" width={75} height={75} /> : <Svg src={preview_selfie} color={icon_color} />)

    const display_front = (imageLoadingFront ? <Loading height={75} width={75} color={loading_color}/> : front_image);

    return (

      <div>

      <div id="selfie"
      style={{cursor:'pointer'}}
        onClick={uploadHandleClick}
      >
      <Row className="dropZone-large justify-content-center align-items-center text-center">
      <Col>
      <div className="d-flex">
        <div>{display_front}</div>
      <div className="ml-auto align-self-end text-left">
      {!imageLoadedFront &&
        <div className="ml-3">
        <p className="pb-0 mb-0 font-weight-bold">Upload a Clear Image of Your Selfie</p>
        <p className="pt-0 mt-0 small text-muted">Click anywhere to browse</p>
        </div>
      }

        {imageLoadedFront &&
          <div>
          <p className="pb-0 mb-0 font-weight-bold">Selfie Image Uploaded</p>
          <p className=" pt-0 mt-0 text-danger small"><i className="far fa-times"></i><span className="ml-1">click to replace</span></p>
          </div>
        }
      </div>
      </div>
      </Col>
    </Row>
    </div>

    </div>

    );
  }
}

export default Selfie;
