import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {withRouter} from 'react-router-dom';
import UploadNav from './Nav';
import FileInput from './Input';
import VerificationProcessing from '../VerificationProcessing';
import {S3Upload, capitalizeFirstLetter} from '../Utils.js'
import '../App.css';

class Index extends Component {

  constructor(props){
    super(props)
    this.state = {
      file: null,
      text: "Upload " + capitalizeFirstLetter(this.props.match.params.side),
      target: "front",
      UploadButtonClass: "veratad-primary",
      loadingFile: false,
      ext: null,
      size: null,
      fileUploaded: null,
      current_region: null,
      region_select: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.changeRegion = this.changeRegion.bind(this)
    this.regionChanged = this.regionChanged.bind(this)
  }

  changeRegion(){
    this.setState({
      region_select: true
    })
  }

  regionChanged(event){
    this.setState({
      region_select: false,
      current_region: event.target.value
    })
    const { data } = this.props;
    data.region = event.target.value;
  }

  handleChange(event) {
    const { settings } = this.props;
    const request_id = settings.request_id;
    if(event.target.files[0]){
    const size = event.target.files[0].size;
    if (size < 5000000){
    this.setState({
      loadingFile: true,
      file: event.target.files[0],
      target: this.props.match.params.side
    })
    S3Upload(event.target.id, this.setState.bind(this), event.target.files[0], request_id, function() {});
  }else{
    this.setState({
      loadingFile: false,
      size: true,
      file: null
    })
  }
}
}

componentDidMount() {
  const { data } = this.props;
  const initial_region = (data.region ? data.region : "United States");
  this.setState({
    current_region: initial_region
  })
}

  render() {
    const { data, settings } = this.props;

    const side = this.props.match.params.side;
    const type = this.props.match.params.type;

    const size = this.state.size;

    return (
      <div className="content">
      <Container>

      {side !== "verificationprocessing" &&
      <div>
      <Row className="text-center mb-3">
        <Col>
          <h2 className="font-weight-bold">Upload Your ID Document</h2>
        </Col>
      </Row>
      <div className="mb-3">
      {type === "dl" && !size &&
        <UploadNav side={side} document_after_front_order={settings.document_after_front_order}/>
      }
      {type === "passport" && !size &&
      <div className="text-center font-weight-bold">Passport</div>
    }
      </div>
        </div>
      }

      {size &&

        <div className="d-flex align-items-center shadow border-left border-danger rounded">
        <div className="pl-2"><i className="fal fa-exclamation-circle text-danger alert-icon"></i></div>
          <div className="ml-4">
            <p className="font-weight-bold mb-0 pb-0 pt-2 pr-2">Image Too Large</p>
            <p className="small mt-0 pt-0 pr-2">The image you are trying to upload is greater than 5MB in size.</p>
          </div>
        </div>
      }

      {side !== "verificationprocessing" &&
      <div>
        <FileInput handleChange={this.handleChange} state={this.state} data={data} settings={settings}/>

        <Row className="text-center mt-3">
          <Col>
          {this.state.region_select && !this.state.loadingFile && data.region_select === "true" &&
          <div>
          <select
          className="form-control"
          value={this.state.current_region}
          onChange={ this.regionChanged }
          >
                <option value="Asia">Asia</option>
                <option value="Australia">Australia</option>
                <option value="Africa">Africa</option>
                <option value="Canada">Canada</option>
                <option value="Europe">Europe</option>
                <option value="Oceania">Oceania</option>
                <option value="South America">South America</option>
                <option value="United States">United States</option>
              </select>
          </div>
        }
        {!this.state.region_select && !this.state.loadingFile && !this.state.fileUploaded && side === "front" && data.region_select === "true" &&
        <div>
        <p className="small pt-0 mt-0 pb-0 mb-0" >
          <span className="mr-2">
            <i className="fal fa-globe"></i>
          </span>
          <span id="new_selected_region">
            {this.state.current_region}
          </span>
        </p>
        <p className="small pt-0 mt-0" >
        Want to change your photo ID region?
        <button
          className="text-primary ml-1 no-style-button"
          href="#"
          onClick={ this.changeRegion }
          >
          Click here
          </button>
      </p>
        </div>

      }
        </Col>
      </Row>
      </div>
      }

      {side === "verificationprocessing" &&
        <VerificationProcessing settings={settings} data={data} message={"Verification in progress..."}/>
      }



    </Container>
  </div>
    );
  }
}

export default withRouter (Index);
