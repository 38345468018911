import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {back, close, inIframe} from './Utils.js'

import './App.css';

/*
Back Button removal
{backButton &&
<div className="back-navigation">
<button
  className="text-muted navigation-button "
  onClick={() => back(history) }
>
<i className="fal fa-arrow-left navigation-item backNav"></i>
</button>
</div>
}
*/

class Nav extends Component {

  constructor(props){
    super(props)
    this.state = {isByPassDesktop: ''}
  }

  componentDidMount(){

    const { data } = this.props;

    if(data){
      if(data.bypassDesktopIntro === true){
        this.setState({isByPassDesktop: true})
      }else{
        this.setState({isByPassDesktop: false})
      }
    }

  }

  render() {
    const { location, history, settings} = this.props;

    let isiFrame = inIframe();

    let backButton = (location.pathname === '/' || (location.pathname === '/qr' && (this.state.isByPassDesktop || this.state.isByPassDesktop === ''))  ? false : location.pathname === '/close' ? false : true );

    return (
      <div>

        {isiFrame && location.pathname !== '/close' &&
        <div className="close-navigation">
        <button
          className="text-muted navigation-button"
          onClick={() => close(history, settings) }
        >
          <i className="fal fa-times navigation-item text-muted"></i>
        </button>
        </div>
      }
        </div>
    );
  }
}

export default withRouter (Nav);
