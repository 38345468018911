import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from '../Nav';
import Loading from '../LoadingIndicator';
import { withRouter } from 'react-router-dom';
import { nextPath, sendEmail, onEmailLink } from '../Utils.js';

import '../App.css';
import './Send.css';


class Send extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      icon: null,
      title: null,
      text: null,
      subtext: null,
      emails: 1,
      error: null
    }
    this.emailAgainClick = this.emailAgainClick.bind(this)
  }

  componentDidMount() {
    onEmailLink();
    sendEmail(this.props.data, this.props.settings, this.setState.bind(this), this.props.history);
  }

  emailAgainClick() {
    this.setState({ isLoading: true, text: null, emails: this.state.emails + 1 });
    sendEmail(this.props.data, this.props.settings, this.setState.bind(this), this.props.history);
  }

  render() {
    const { history, settings, data } = this.props;

    const loading_color = settings.style[0].settings.loading;
    const title = (this.state.error ? settings.text.email.titleFailure : settings.text.email.titleSuccess);
    const explanation = (this.state.error ? settings.text.email.explanationFailure : settings.text.email.explanationSuccess);
    const sub = (this.state.error ? settings.text.email.subTitleFailure : settings.text.email.subTitleSuccess);
    const isManual = settings.isManualCapture;
    return (
      <div>
        <Nav />
        <div className="content">

          {this.state.isLoading &&
            <Container className="h-100">
              <Row className="text-center h-100 justify-content-center align-items-center">
                <Col>
                  <Loading height={100} width={100} color={loading_color} />
                </Col>
              </Row>
            </Container>
          }

          {!this.state.isLoading &&
            <div>
              <Row className="text-center">
                <Col>
                  <h4 className="font-weight-bold">{title}</h4>
                </Col>
              </Row>
              <Row className="text-center mt-5">
                <Col>
                  <i className="fal fa-paper-plane result-icon"></i>
                </Col>
              </Row>
            </div>
          }

          {!this.state.isLoading &&
            <Row className="mt-5 text-center">
              <Col>
                <p className="font-weight-bold">{explanation} {data.email}</p>
                <p className="text-muted small">{sub}</p>
              </Col>
            </Row>
          }

          {!this.state.isLoading &&

            <Row className="mt-5">
              {this.state.emails <= 1 && !this.state.error &&
                <Col>
                  <button
                    type="button"
                    value="email"
                    className="veratad-secondary mb-4"
                    onClick={this.emailAgainClick}
                  >
                    {settings.text.email.secondaryButton}
                  </button>
                </Col>
              }
              {isManual &&
                <Col>
                  <button
                    type="button"
                    value="device"
                    className="veratad-primary mb-4"
                    onClick={() => nextPath(history, '../snap/upload/dl')}
                  >
                    {settings.text.email.actionButton}
                  </button>
                </Col>
              }
            </Row>
          }

        </div>
      </div>
    );
  }
}

export default withRouter(Send);
