import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Verify from './Verify.js'; 
import '../../App.css';
var QRCode = require('qrcode.react');

function Qr(props) {
  const { settings } = props;
  const [url, setUrl] = useState('');
  const [qrStatus, setQrStatus] = useState(false);

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.startsWith('127.0.0.') || window.location.hostname.startsWith('0.0.0.0');

    if (isLocalhost) {
      setUrl('https://10.0.0.29:3000' + '?request_id=' + settings.request_id + '&qr=true');
    } else {
      setUrl(process.env.REACT_APP_FRONTEND_URL + '?request_id=' + settings.request_id + '&qr=true');
    }
  }, []);

  const fetchQrStatus = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/v3/stable/api/frontend/qrStatus', {
        method: 'POST',
        body: JSON.stringify({ request_id: settings.request_id, type: "get", site_name: props.settings.site_name }),
      });

      const data = await response.json();

      if (data.found) {
        setQrStatus(true);
        return true;
      }
    } catch (error) {
      console.error('Error fetching QR status:', error);
    }
    return false;
  };

  useEffect(() => {
    if (qrStatus) {
      props.onQrStatusChange(true);
    }
  }, [qrStatus]);

  useEffect(() => {
    const fetchInterval = async () => {
      const found = await fetchQrStatus();
      if (!found) {
        setTimeout(fetchInterval, 2000);
      }
    };

    fetchInterval();
  }, [settings.request_id]);

  const handleContinue = () => {
    props.onContinue();
  };

  return (
    <div className="content">
      <Row>
        <Col>
          {!qrStatus && (
            <div>
              <Row className="text-center border-bottom w-100 pt-0 pb-0">
                <Col>
                  <h3>Age Confirmation</h3>
       
                </Col>
              </Row>
  
  
              <Row className="d-flex align-items-center justify-content-start p-3 mt-4" style={{ backgroundColor: "rgba(0, 0, 255, 0.2)" }}>
                <Col xs="auto">
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <i className="fas fa-mobile-alt fa-2x text-dark"></i>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className="align-items-center text-left justify-content-start">
                    <Col xs="auto">
                      <p className="text-dark mb-1 font-weight-bold">Use Your Phone</p>
                      <p className="text-dark mb-0 small">It may be easier to complete this process on your phone.</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
  
              <Row className="text-center mt-5">
                <Col>
                  <QRCode value={url} size={150} />
                </Col>
              </Row>
  
              <Row className="text-center mt-3">
                <Col>
                  <p>Scan the QR Code with your camera app</p>
                </Col>
              </Row>
            {props.data.desktopToMobileHandoff?.continueIsAnOption &&
              <Row className="text-center mt-3">
              <Col>
                <Button variant="link" onClick={handleContinue}>Or continue on this device</Button>
              </Col>
            </Row>
            }
              
            </div>
          )}
  
        </Col>
      </Row>
    </div>
  );
  
}

export default Qr;