import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {capitalizeFirstLetter} from '../Utils.js'
import '../App.css';

class Nav extends Component {

  render() {

    const { side, document_after_front_order } = this.props;

    var text_active = (side === "front" ? 'font-weight-bold m-3' : 'text-muted m-3');

    const order = document_after_front_order;
    const navItems = order.map((navItem, index) =>
    <div key={navItem}>
        <p className={(side === navItem ? 'font-weight-bold m-3' : 'text-muted m-3')}>{capitalizeFirstLetter(navItem)}</p>
    </div>
    );

    return (
      <div>

      <div className="d-flex justify-content-center">
        <div>
          <p className={text_active}>Front</p>
        </div>
        {navItems}
      </div>
  </div>
    );
  }
}

export default withRouter (Nav);
