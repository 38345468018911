import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import './App.css';


class LoadingIndicator extends Component {


  render() {
    const { height, width, color } = this.props;

    const set_color = (color ? color : "#172DDC");

    return (
    <div>
      <Container >
        <Row>
          <Col >
          <Loader
        type="ThreeDots"
        color={set_color}
        height={height}
        width={width}
        timeout={100000}
     />
          </Col>
        </Row>
      </Container>
    </div>
    );
  }
}

export default LoadingIndicator;
