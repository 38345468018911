import React, { Component } from "react";
import MethodSelect from "./MethodSelect";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Lottie from 'lottie-react-web'
import idScan from '../assets/id-scan.json';
import { Redirect } from 'react-router-dom'
import '../App.css';
import IntroModal from '../snap/scan/v2/components/IntroModal'; 

class Intro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
    };

    this.stop = this.stop.bind(this)
  }


  stop(){
    this.setState({isStopped:true})
  }

  componentDidMount() {
    this.interval = setInterval(() => this.stop(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderRedirect = () => {
      const { data, settings} = this.props;
      if(settings.isAgeMatch){
        return <Redirect to='/agematch' />
      }else if(data.bypassDesktopIntro && data.qr){
        return <Redirect to='/qr' />
      }else if(data.bypassDesktopIntro && !data.qr && data.email_fallback){
        return <Redirect to='/email/send' />
      }else if(data.bypassDesktopIntro && !data.qr && !data.email_fallback){
        return <Redirect to='/snap/upload/dl' />
      }

  }

render() {
  const { data, settings } = this.props;

      let logo_url;
      if(settings.style){
        logo_url = (settings.style[0].logo_url ? settings.style[0].logo_url : null);
      }else{
        logo_url = null;
      }


      return (  <div className="content">
          {this.renderRedirect()}
          <Container >

          {settings.site_name.search(/superawesome/i) !== -1 && 
            <IntroModal text={settings.text}/>
          }

          <Row className="text-center">
            <Col>
              <h4  className="font-weight-bold">{settings.text.desktopIntro.title}</h4>
            </Col>
          </Row>

          {logo_url &&

              <div style={{minHeight:"250px", height:"250px"}} className="d-flex justify-content-center text-center">

                  <img alt="logo" className="align-self-center" src={logo_url} style={{maxWidth:"250px", maxHeight:"250px"}}/>

              </div>

          }

        {!logo_url &&
          <Row className="text-center mb-0 pb-0">
            <Col>
          <Lottie
            options={{
                animationData: idScan,
              }}
            width={250}
            ariaRole={"img"}
            isStopped={this.state.isStopped}
            title={"id-scan"}
            />
            </Col>
          </Row>
        }


          <Row className="text-center mt-0 pt-0">
            <Col>
            <MethodSelect settings={settings} data={data}/>
            </Col>
          </Row>

        </Container>
      </div>
    );
  }
}

export default Intro;
