import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import {withRouter} from 'react-router-dom';
import {statusCheck, close, inIframe} from './Utils.js'
import './App.css';

class VerificationProcessing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      action: null,
      icon: null,
      header: null,
      sub: null,
      enhanced_template: null,
      enhanced_complete: null,
      check_time_selfie: 0,
      isTryAgain: false
    };
  }

  check() {
    const { settings, data, history, selfie } = this.props;
    if(this.state.isLoading){
      statusCheck(this.setState.bind(this), settings, data, history, selfie );
    }else{
      clearInterval(this.interval);
    }
  }

  componentDidMount() {
    const status_check_ms = (process.env.REACT_APP_STATUS_CHECK_MS ? process.env.REACT_APP_STATUS_CHECK_MS : 2000);
    this.interval = setInterval(() => this.check(), status_check_ms);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { settings, history } = this.props;

    const set_color = "#172DDC"

    let isiFrame = inIframe();

    return (

      <div className="content">

      {this.state.isLoading &&
        <Container className="h-100">
          <Row className="text-center h-100 justify-content-center align-items-center">
            <Col>
              <Loader
                  type="Oval"
                  color={set_color}
                  height={100}
                  width={100}
                  timeout={100000}

               />

               <p className="text-muted h5 mt-4" >{settings.text.loading.verify}...</p>
            </Col>
      </Row>
    </Container>
  }


  {!this.state.isLoading &&
    <Container className="h-100">
      <Row className="text-center h-100 justify-content-center align-items-center">
        <Col>
    <Row className="text-center">
      <Col>
        <i className={this.state.icon + "result-icon"}></i>
      </Col>
    </Row>

    <Row className="text-center mt-3">
      <Col>
        <h2 className="font-weight-bold">{this.state.header}</h2>
      </Col>
    </Row>

    <Row className="text-center mt-0 mb-3">
      <Col>
        <p>{this.state.sub}</p>
      </Col>
    </Row>

    {isiFrame &&
      <Row className="text-center mt-5">
        <Col>
              <button
                type="button"
                className="veratad-primary w-50"
                onClick={() => close(history, settings) }
                >
                {settings.text.result.close}
              </button>
          </Col>
      </Row>
    }

    </Col>
</Row>
    </Container>
  }

    </div>
    );
  }
}

export default withRouter (VerificationProcessing);
