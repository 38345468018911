import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BrowserRouter as Router } from 'react-router-dom';
import Nav from './Nav';
import { Redirect } from 'react-router-dom'

import './App.css';

class Error extends Component {

  renderRedirect = () => {

      return <Redirect to='/error' />

  }

  render() {
    const { message, description } = this.props;
    return (
      <div>
      <Router>
      {this.renderRedirect()}
      <Nav />
      </Router>
    <div className="content">
      <Container className="h-100 mt-5">

        <Row className="text-center h-100 justify-content-center align-items-center">
          <Col>
      <Row className="text-center">
        <Col>
          <i className="fal fa-exclamation-circle error-icon"></i>
        </Col>
      </Row>

      <Row className="text-center mt-3">
        <Col>
          <h2 className="font-weight-bold">{message}</h2>
        </Col>
      </Row>

      <Row className="text-center mt-0 mb-3">
        <Col>
          <p>{description}</p>
        </Col>
      </Row>

      </Col>
    </Row>

      </Container>
    </div>
    </div>
    );
  }
}

export default Error;
