import React, { useState } from 'react';
import { LoadingOverlay, FocusTrap, Text, MantineProvider, PinInput, Image, Title, TextInput, Button, Group, Stack, Center, Container, Flex, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
//import { IconShieldCheck } from '@tabler/icons';
import phone_icon from './icon.svg'
import alert from './alert-circle.svg'

export default function PhoneMatch({ data, settings }) {

    const [loading, setLoading] = useState(false);
    const [isOtpInput, setIsOtpInput] = useState(false);
    const [template, setTemplate] = useState({});
    const [result, setResult] = useState(false);
    const [resultStatus, setResultStatus] = useState('');

    const form = useForm({
        initialValues: {
            fn: data.fn || '',
            ln: data.ln || '',
            phone: data.phone || '',
            pin: ''
        },
        validate: (values) => {
            if (isOtpInput) {
                return {
                    pin: values.pin.length < 6 ? 'Please enter the pin' : null,
                };
            } else {
                return {
                    phone: values.phone.replace(/\D/g, "").length !== 10 ? "Phone number must be 10 digits" : null,
                    fn: values.fn.length < 2 ? 'First name must have at least 2 letters' : null,
                    ln: values.ln.length < 2 ? 'Last name must have at least 2 letters' : null,
                };
            }
        },
    })

    const submitHandler = () => {
        data.phone = form.values.phone
        data.fn = form.values.fn
        data.ln = form.values.ln
        data.isOtpInput = isOtpInput
        if (isOtpInput) {
            template.codematch.key = form.values.pin
            data.phonematch_continue = template
        }
        const errors = form.validate()
        if (!errors.hasErrors) {
            setLoading(true)
            console.log('sending the api call')
            fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/phonematch",
                {
                    method: "POST",
                    body: JSON.stringify(data)
                })
                .then(function (res) { return res.json(); })
                .then(function (data) {
                    setLoading(false)
                    if (data.result.action === "PENDING" && !isOtpInput) {
                        setTemplate(data.continuations.outofband.template)
                        setIsOtpInput(true)
                    } else {
                        setResult(true)
                        setResultStatus('FAIL')
                        window.top.postMessage('FAIL', '*');
                    }
                    if (data.result.action === "PASS" && isOtpInput) {
                        setResult(true)
                        setResultStatus('PASS')
                        window.top.postMessage('PASS', '*');
                    } else if (data.result.action !== "PASS" && isOtpInput) {
                        window.top.postMessage('FAIL', '*');
                        setResultStatus('FAIL')
                    }
                })
                .catch(function () { alert("Something Went Wrong! Please try again.") })
        }
    }
    if (loading) {
        return (<LoadingOverlay visible />);
    }

    const resultMessage = (
        <>
            <Container>
                <Flex style={{ height: '100vh' }} align="center" justify="center">
                    <Paper padding="md" align="center">
                        <Image src={resultStatus === 'PASS' ? phone_icon : alert} width={70} />
                        <Text fz={25} fw={700}>{resultStatus === 'PASS' ? 'Success' : 'Failure'}</Text>
                        <Text c="dimmed">{resultStatus === 'PASS' ? 'You have been successfully verified' : 'We were unable to verify you'}</Text>
                    </Paper>
                </Flex>
            </Container>
        </>
    )

    if (result) {
        return (
            resultMessage
        )
    }


    return (
        <MantineProvider theme={
            {
                fontFamily: 'Poppins',
                colors: {
                    'ocean-blue': [
                        '#B3D1FC',  // Lightest shade
                        '#9BC3FA',
                        '#82B5F8',
                        '#6AA8F6',
                        '#529BF3',
                        '#3A8EF1',
                        '#227FF0',
                        '#096FFD',
                        '#0060EA',
                        '#0056D7'   // Darkest shade close to #3897f0
                    ],
                    'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
                },
                primaryColor: 'ocean-blue'
            }
        } withGlobalStyles withNormalizeCSS>
            <form onSubmit={form.onSubmit(submitHandler)}>
                {!isOtpInput && (
                    <>
                        <Center mt={50}>
                            <Stack align="center">
                                <Image src={phone_icon} width={70} />
                                <Text fz={25} fw={700} align='center'>Let's Verify Your Identity</Text>
                                <Text c="dimmed" align='center'>In order to verify your identity please confirm or enter your details. We will then send you a one time code via SMS.</Text>
                            </Stack>
                        </Center>
                        <Stack mt={30} spacing="xl">
                            <TextInput
                                withAsterisk
                                label="First Name"
                                placeholder="First Name"
                                {...form.getInputProps('fn')}
                            />
                            <TextInput
                                withAsterisk
                                label="Last Name"
                                placeholder="Last Name"
                                {...form.getInputProps('ln')}
                            />
                            <TextInput
                                withAsterisk
                                label="Phone"
                                placeholder="555-555-5555"
                                {...form.getInputProps('phone')}
                                onChange={(event) => {
                                    let value = event.target.value || '';
                                    value = value.replace(/\D/g, "").substring(0, 10);
                                    value = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                                    form.setFieldValue('phone', value);
                                }}
                            />
                        </Stack>
                    </>
                )}
                {isOtpInput && (
                    <>

                        <Center mt={90}>
                            <Stack align="center">
                                <Image src={phone_icon} width={70} />
                                <Text fz={25} fw={700}>Please enter your pin</Text>
                                <Text c="dimmed">Please enter the one time code that was sent to {form.values.phone}</Text>
                            </Stack>
                        </Center>
                        <Group position="center" mt={40}>
                            <FocusTrap>
                                <PinInput data-autofocus type="number" oneTimeCode size="xl" length={6} {...form.getInputProps('pin')} />
                            </FocusTrap>
                        </Group>
                    </>

                )}
                <Button mt="xl" fullWidth type="submit">{isOtpInput ? 'Verify' : 'Send me the code'}</Button>
            </form>
        </MantineProvider>
    );
}