import AWS from 'aws-sdk/dist/aws-sdk-react-native';
import FrontDl from '../assets/dl_front.svg';
import BackDl from '../assets/dl_back.svg';


export const displayError = (value, request, setState, errorCount, subtitle) => {

  if(request === "DL_FRONT"){
    setState({errorCount: errorCount + 1, preview_front: FrontDl, imageLoadingFront: false, imageLoadedFront: false, error:true, errorTitle: value, errorSubTitle: subtitle});
  }else if(request === "PDF417_BARCODE"){
    setState({errorCount: errorCount + 1, preview_back: BackDl, imageLoadingBack: false, imageLoadedBack: false, error:true, errorTitle: value, errorSubTitle: subtitle});
  }else{
    setState({errorCount: errorCount + 1, preview_front: FrontDl, imageLoadingFront: false, imageLoadedFront: false, error:true, errorTitle: value, errorSubTitle: subtitle});
    setState({errorCount: errorCount + 1, preview_back: BackDl, imageLoadingBack: false, imageLoadedBack: false, error:true, errorTitle: value, errorSubTitle: subtitle});
  }
}

export const acceptImage = (result, setState, request_id, international, currentScan) => {


  let side = '';
  let type = '';

  if(international){

    if(currentScan === "front"){
      side = "front";
      type = "front";
    }else if (currentScan === "back"){
      side = "back";
      type = "back";
    }else if(currentScan === "passport"){
      side = "passport";
      type = "passport";
    }else{
      side = "selfie";
      type = "selfie";
    }

  }else{


    if(result.request === "DL_FRONT"){
      side = "front";
      type = "front";
    }else if (result.request === "PDF417_BARCODE"){
      side = "back";
      type = "back";
    }else if(result.request === "PASSPORT"){
      side = "passport";
      type = "passport";
    }else{
      side = "selfie";
      type = "selfie";
    }
  }


  S3UploadSnap(side, setState, result.response.imageData, request_id, function() {

    if(type === "front"){
      setState({isLoading: false, preview_front: result.response.imageData, imageLoadingFront: false, imageLoadedFront: true, error:false})
    }else if (type === "back"){
      setState({isLoading: false, preview_back: result.response.imageData, imageLoadingBack: false, imageLoadedBack: true, error:false})
    }else if(type === "passport"){
      setState({isLoading: false, preview_passport: result.response.imageData, imageLoadingPassport: false, imageLoadedPassport: true, error:false})
    }else{
      setState({isLoading: false, preview_selfie: result.response.imageData, imageLoadingFront: false, imageLoadedFront: true, error:false})
    }

  });

}

const S3UploadSnap = (id, setState, imageUri, request_id, _callback) => {

  if(id === "back"){
    setState({imageLoadingBack: true})
  }else if (id === "front"){
    setState({imageLoadingFront: true})
  }else{
    setState({imageLoadingPassport: true})
  }

  var albumBucketName = "dcamsv3";
  var bucketRegion = "us-east-2";
  var IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b";
  var albumName = "upload";

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

  if(id === "passport"){
    id = "front";
  }

  var name = "dl_" + id;
  var fileName = request_id + "_" + name + ".jpeg";
  var file_blob = dataURItoBlob(imageUri);


  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file_blob,
      ACL: ""
    }
  });

  var promise = upload.promise();

  promise.then(
    function(data) {

      console.log('here is the data from upload', data); 

      _callback();

    },
    function(err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  );

}

const dataURItoBlob = (dataURI) => {

  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const dataImagetoBlob = (array) => {
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }

  export const getBlinkKey = () => {

    let licenseKey = null; 
        if(window.origin === "https://localhost:3000"){
          licenseKey = 'sRwAAAYJbG9jYWxob3N0r/lOPgo/w35CpJlWKlveBqCMNjKQ1qQt/TZsRNK/qFkrPQ6lLpMnPWaQpQs4wzmLri0PAHj5NWQncNgdQCTI1pubX7kYsXLuN2yLh6/K9YAJzcH8WqCMKK3qdFqPQOrrIl1ySF3qrY3Uo319QzSXCJeNI4R6qVQBr6o+B/kvvak6WPLUYsBL3IJRllV32i2o4WNvRVWmcq196VlnCKmnbshDPvd79vdw0A==';
        }
        if (window.origin === "https://10.0.0.29:3000"){
          licenseKey = 'sRwAAAYJMTAuMC4wLjI5W0DlL6S6J6MuY79Ah//3mlxGYwT+1MDCI/ANew+jSRcfUgQQpLdhlWzRie/oJUGS6OKQywOv2h95PXkdr5Rwud7cciLIB1xV1aR5ekdInZHmOPOHciuDpsPmsOhvK8cWaUTU1qzct38QAqKfqIqxSsnB5NguyJ+weHyAkLtIBN2BiXGkmtbt0Ai9YK9mJghSB9UVnVFMGyI+7K0bwEmKlQQBaObHDazf0A=='; 
        }
        if (window.origin === "https://staging-frontend.dcams.app"){
          licenseKey = 'sRwAAAYac3RhZ2luZy1mcm9udGVuZC5kY2Ftcy5hcHCrlq1JrpXk5ak1B88GZI+8n+9UpHVyM2e8lAXymP7dHJv4eGH3f2gpkNshpbAOuANNe09cWEgSh6xdkVQvtirVajESsdS1OFxpmuRRyqrxIOYygFKBHkF/8rVTaGUGlPTUvLxXmkx+u4c+hvxKUZ5vBmsszhFMcEU2C/Trr1Wj+UdA/PnYHb+IovgnMbp0XzDT3PsL9ViTM0ONyFtv64Kn43UD/hrwfNV/';
        }
        if (window.origin === "https://frontend.dcams.app"){
          licenseKey = 'sRwAAAYSZnJvbnRlbmQuZGNhbXMuYXBwqr5Px+xz8kOR/ycKrhatyQ+K2BMtzz3YxTWysNiyB6QBd8nVkRPd29IP9j4F2yUucAAKasn6SSkDUPvzws4/o2T1hQqDefuWtiaOZbgpUD6Z409KZd/ZWi1hHbWn22JLOTiZUg8rPBU1A2EEFWW3bfox3HPDnD24y9gXPiG8HZgpg5NKGw4fouZUW+P9LA2OeVccW7FLdn5MXtU+a5dAkT5a6jybsj6baw==';
        }

        return licenseKey; 
        
  }

export const S3UploadBlink = (id, imageData, request_id, _callback) => {

    var albumBucketName = "dcamsv3";
    var bucketRegion = "us-east-2";
    var IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b";
    var albumName = "upload";
  
    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
      })
    });
  
    if(id === "passport"){
      id = "front";
    }
  
    var name = "dl_" + id;
    var fileName = request_id + "_" + name + ".jpeg";
  
    var albumPhotosKey = encodeURIComponent(albumName) + "/";
  
    var photoKey = albumPhotosKey + fileName;

    var file_blob = dataImagetoBlob(imageData);
  
    // Use S3 ManagedUpload class as it supports multipart uploads
    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file_blob,
        ACL: ""
      }
    });
  
    var promise = upload.promise();
  
    promise.then(
      function(data) {
  
        console.log('here is the data from upload', data); 
  
        _callback( );
  
      },
      function(err) {
        return alert("There was an error uploading your photo: ", err.message);
      }
    );
  
  }
