import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {verifyPost, back} from '../../../Utils.js';

import '../../../App.css';


class Confirmation extends Component {

  render() {

    const { image_preview, history, data, settings } = this.props;

    data.type = 'SDK_Scan';

      return ( <div>

        <Row className="text-left mb-0 pb-0">
          <Col>
            <h3 className="font-weight-bold">{settings.text.confirmation.title}</h3>
            <p>{settings.text.confirmation.subTitleSelfie}</p>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="text-center">
            <img src={image_preview} alt="Selfie Preview" height={300}/>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={6} md={6}>
            <button
              type="button"
              className="veratad-primary mb-4"
              onClick={() => verifyPost(data, settings, history) }
            >
            {settings.text.confirmation.actionButtonVerify}
            </button>
          </Col>
          <Col sm={6} md={6}>
              <button
                type="button"
                value="device"
                className="veratad-secondary mb-4"
                onClick={() => back(history) }
              >
              {settings.text.confirmation.secondaryButton}
              </button>
          </Col>
        </Row>





    </div>
  );

  }
}

export default withRouter (Confirmation);
