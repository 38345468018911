import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { nextPath } from '../../Utils.js'
import Container from 'react-bootstrap/Container';
import '../mitekSDK.css';
import Confirmation from './components/Confirmation';
import Loading from '../../Loading';
import { sdkWrapper } from '../sdkWrapper';
import { acceptImage } from '../Utils.js';
import VerificationProcessing from '../../VerificationProcessing';
import YotiWrapper from '../../estimation/components/providers/yoti/YotiWrapper';
import {
  S3Upload,
} from '../../estimation/helpers';

import '../../App.css';

class Scan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentScan: "front",
      capture: true
    };

    this.handleException = this.handleException.bind(this)
    this.handleResultYoti = this.handleResultYoti.bind(this)
  }

  componentDidMount() {
    const { data } = this.props;
    data.type = "SDK_Scan"
  }


  handleException(excpt) {
    this.setState({ isLoading: false })
    const { history } = this.props;
    //nextPath(history, '/snap/upload/selfie')
  };


  base64ToDataUri = (base64) => {
    return `data:image/jpeg;base64,${base64}`;
  };

  handleResultYoti = async (result, secure) => {
    const { data } = this.props;
    if (!result.startsWith("data:image/jpeg;base64,")) {
      result = this.base64ToDataUri(result);
    }
    if (result) {
      S3Upload("selfie", result, data.request_id, () => {
        this.setState({ isLoading: false, preview: result, capture: false })
      });
    }
  }


  render() {

    const { isLoading, capture } = this.state;
    const { history, data, settings } = this.props;

    const side = this.props.match.params.side;

    if (capture && isLoading) {
      return (
        <YotiWrapper onData={this.handleResultYoti} onError={this.handleException} secure={false} />
      )
    }

    if (isLoading) {
      return (<Loading message={"Camera access is required in order to take your selfie."} message_two={"Please allow camera access to continue."} />);
    } else {
      return (<div className="content">
        <Container>

          {side !== "verificationprocessing" &&
            <Confirmation image_preview={this.state.preview} history={history} data={data} settings={settings} />
          }

          {side === "verificationprocessing" &&
            <VerificationProcessing data={data} selfie={true} settings={settings} message={"Verification in progress..."} />
          }

        </Container>
      </div>);
    }
  }
}

export default withRouter(Scan);
