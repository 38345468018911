import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from '../Nav';
import Loading from '../LoadingIndicator';
import {withRouter} from 'react-router-dom';
import {nextPath, onQr} from '../Utils.js';
import '../App.css';

var QRCode = require('qrcode.react');

class Index extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading: true,
      url: null,
      issue: null,
      error: false,
      errorMessage: null,
      errorDescription: null,
      settings: this.props.settings,
      regenerate: false,
      requestID: null
    }
    this.addIssue = this.addIssue.bind(this)
    this.removeIssue = this.removeIssue.bind(this)


  }

  componentDidMount(){

    //const data = this.props.data;
    const settings = this.state.settings;
    const request_id = settings.request_id;

    var qrUrl = process.env.REACT_APP_FRONTEND_URL + '?request_id=' + request_id;

    this.setState({isLoading: false, url: qrUrl});

    //registerQr( data, settings, this.setState.bind(this), this.props.history );
  }

/*
  regenerateQr(){

    const that = this;
    that.setState({
      isLoading: true,
    });

    const { data } = this.props;

    const post = data;

    const device = {osName: osName, osVersion: osVersion, browserName: browserName, browserVersion: browserVersion, mobileVendor: mobileVendor, mobileModel: mobileModel};

    post['device'] = device;

    if(post.email){
      post['email'] = post.email.replace(/\s/g, "+");
    }

    fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/register.php",
  {
      method: "POST",
      body: JSON.stringify(post)
  })
  .then(function(res){ return res.json(); })
  .then(function(data){
    if(data.action === "ERROR"){
      that.setState({
        isLoading: false,
        error: true,
        errorMessage: data.errorMessage,
        errorDescription: data.errorDescription
      });
      window.top.postMessage(data.detail, '*');
    }else{


        that.setState({
          isLoading: false,
          settings: data,
          error: false,
          url: process.env.REACT_APP_FRONTEND_URL + '?request_id=' + data.request_id,
          requestID: data.request_id
        });



        const details = this.props.data;
        registerQr( details, data, that.setState.bind(that), this.props.history );

    }
  })
  .catch(function() { that.setState({isLoading: false, error: true, errorMessage: "Something Went Wrong",errorDescription: "This is a system error. Please contact customer service."}); })


  }

  */


  addIssue(){
    const { history, data } = this.props;
    if(!data.email_fallback){
      nextPath(history, '../snap/upload/dl');
    }
    this.setState({issue: true});
  }

  removeIssue(){
    this.setState({issue: null});
  }

  qrDone(){
    onQr();
  }

  render() {
    const { history, settings, data } = this.props;
    //const { isLoading } = this.state;

    const qrExplanation = (!data.email_fallback ? settings.text.qr.explanationSubtitleIssueNoFallback : settings.text.qr.explanationSubtitleIssue);

    return (
      <div>
        <Nav settings={settings}/>
      <div className="content">

      <Row className="text-center">
        <Col>
          <h4 className="font-weight-bold">{settings.text.qr.title}</h4>
        </Col>
      </Row>

      <Row className="text-center mt-5">
        <Col>
        {!this.state.isLoading &&
          <QRCode value={this.state.url} size={175}/>
        }
        {this.state.isLoading &&
          <Loading />
        }
        </Col>
      </Row>

      {this.state.regenerate &&
      <Row className="mt-2 text-center">
        <Col>
          <button
            type="button"
            className="no-style-button small text-muted"
            onClick={ this.regenerateQr}
          >
          Regenerate QR Code
          </button>
        </Col>
      </Row>
    }


          <Row className="mt-3 text-center">
            <Col>
              <p className="font-weight-bold">{settings.text.qr.explanation}</p>
              {this.state.issue === null &&
              <p className="text-muted small mt-0">{settings.text.qr.explanationSubtitle}</p>
              }
            {this.state.issue !== null &&
              <p className="text-muted small mt-0">{qrExplanation}</p>
            }
            </Col>
          </Row>

          {this.state.issue === null &&
            <div>
            <Row className="mt-3">
              <Col>
                <button
                  type="button"
                  className="veratad-primary"
                  onClick={ this.qrDone }
                >
                {settings.text.qr.actionButton}
                </button>
              </Col>
            </Row>
            <Row className="text-center text-muted small mt-4">
              <Col>
                <button
                  type="button"
                  className="no-style-button "
                  onClick={this.addIssue}
                >
                {settings.text.qr.secondaryButton}
                </button>
              </Col>
            </Row>
            </div>
          }

          {this.state.issue !== null &&
            <div>
          <Row className="mt-3">
          {data.email_fallback &&
            <Col>
              <button
                type="button"
                className="veratad-secondary mb-4"
                onClick={() => nextPath(history, '../email/send') }
              >
              {settings.text.qr.troubleSecondaryButton}
              </button>
            </Col>
          }

            <Col>
                <button
                  type="button"
                  value="device"
                  className="veratad-primary mb-4"
                  onClick={() => nextPath(history, '../snap/upload/dl') }
                >
                {settings.text.qr.troublePrimaryButton}
                </button>
            </Col>
          </Row>
          <Row className="text-center text-muted small mt-4">
            <Col>
              <button
                type="button"
                className="no-style-button "
                onClick={this.removeIssue}
              >
              {settings.text.qr.secondaryButtonNoTrouble}
              </button>
            </Col>
          </Row>
          </div>
          }


    </div>
    </div>
    );
  }

}

export default withRouter (Index);
