import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DesktopIntro from "./desktop/Intro";
import Nav from "./Nav";
import SnapScanIntro from "./snap/scan/Intro";
import Blink from "./blink/Index";
import EmailSend from "./email/Send";
import Upload from "./upload/Index";
import Scan from "./scan/Index";
import SnapScan from "./snap/scan/Index";
import ScanSideStart from "./snap/scan/ScanSideStart";
import ScanError from "./snap/scan/CaptureFailure";
import SelfieIntro from "./snap/selfie/Index";
import SelfieRetry from "./snap/selfie/Retry";
import SelfieScan from "./snap/selfie/Scan";
import SnapUpload from "./snap/upload/Index";
import AgeMatch from "./agematch/Index";
import TryAgain from "./agematch/TryAgain";
import AgeEstimation from "./estimation/Index.js";
import DocumentTypeIntro from "./snap/documentTypeIntro/Index";
import DocumentTypeIntroMobile from "./snap/documentTypeIntro/mobile/Index";
import PhoneMatch from './phonematch'
import Qr from "./qr/Index";
import VerificationProcessing from "./VerificationProcessing";
import ManualWait from "./ManualWait";
import Close from "./Close";
import Loading from "./Loading";
import ErrorManual from "./ErrorManual";
import Error from "./Error";
import Container from 'react-bootstrap/Container';
//import Geo from "./geo/Index.js";
import './App.css';
import MediaQuery from 'react-responsive'


const App = ({ data, settings }) => (

  <Router>
    <div>
      <Route render={({ location }) => {
        const lastItem = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        return lastItem === 'verificationprocessing' ? null : <Nav data={data} settings={settings} />
      }} />
      <Container className="mt-5">
        <Switch>

          <Route path="/loading">
            <Loading />
          </Route>

          <Route path="/error">
            <Error message={"Something Went Wrong"} description={"This was a system error. Please contact customer service."} />
          </Route>

          <Route path="/close">
            <Close settings={settings} />
          </Route>

          <Route path="/verificationprocessing">
            <VerificationProcessing settings={settings} />
          </Route>

          <Route path="/manualwait">
            <ManualWait settings={settings} data={data} />
          </Route>

          <Route exact path="/email/send">
            <EmailSend data={data} settings={settings} />
          </Route>

          <Route path="/qr">
            <Qr data={data} settings={settings} />
          </Route>

          <Route exact path="/snap/selfie">
            <SelfieIntro settings={settings} />
          </Route>

          <Route exact path="/snap/selfie/retry">
            <SelfieRetry data={data} settings={settings} />
          </Route>

          <Route exact path="/snap/selfie/scan/:side">
            <SelfieScan data={data} settings={settings} />
          </Route>

          <Route exact path="/errormanual">
            <ErrorManual data={data} settings={settings} />
          </Route>

          <Route exact path="/snap/documentTypeIntro">
            <DocumentTypeIntro data={data} settings={settings} />
          </Route>

          <Route exact path="/snap/documentTypeIntro/mobile">
            <DocumentTypeIntroMobile data={data} settings={settings} />
          </Route>

          <Route path={`${process.env.PUBLIC_URL}/upload/:type/:side`}>
            <Upload data={data} settings={settings} />
          </Route>

          <Route path={`${process.env.PUBLIC_URL}/scan/:type`}>
            <Scan data={data} settings={settings} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/snap/scan/scansidestart/:type/:side`}>
            <ScanSideStart data={data} settings={settings} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/snap/scan/capturefailure`}>
            <ScanError data={data} settings={settings} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/snap/scan/:type/:side`}>
            <SnapScan data={data} settings={settings} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/snap/upload/:type`}>
            <SnapUpload data={data} settings={settings} />
          </Route>

          <Route path="/agematch/">
            <AgeMatch data={data} settings={settings} />
          </Route>

          <Route path="/agematch/try-again">
            <TryAgain data={data} settings={settings} />
          </Route>
{/* 
          <Route path="/">
          <AgeEstimation data={data} settings={settings} />
            </Route>
        */}
          <Route path="/">
            {settings.isEstimation &&
              <AgeEstimation data={data} settings={settings} />
            }
            {settings.isPhoneMatch &&
               <PhoneMatch data={data} settings={settings} />
            }
          {!settings.isPhoneMatch && !settings.isEstimation &&
              <MediaQuery maxDeviceWidth={1224}>
                {(matches) =>
                  matches
                    ? (settings.isBlink ? <Blink settings={settings} data={data} /> : <SnapScanIntro settings={settings} data={data} />)
                    : <DesktopIntro data={data} settings={settings} />
                }
              </MediaQuery>
              }
             
            
          </Route>
         


        </Switch>
      </Container>
    </div>
  </Router>

);

export default App;