import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import {nextPath} from '../../Utils.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Lottie from 'lottie-react-web'
import idScan from '../../assets/id-scan.json';
import { Redirect } from 'react-router-dom'
import '../../App.css';
import IntroModal from './v2/components/IntroModal';


class Intro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
    };

    this.stop = this.stop.bind(this)
  }

  stop(){
    this.setState({isStopped:true})
  }

  componentDidMount() {
    this.interval = setInterval(() => this.stop(), 10000);
  }

  renderRedirect = () => {
      const { settings } = this.props;
      if(settings.isAgeMatch){
        return <Redirect to='/agematch' />
      }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

render() {
  const { history, settings, data } = this.props;

  const sitesThatDoNotGetScan = [
    "hedydevrjrt", 
    'hedystagerjrt', 
    "hedyprodrjrt", 
    "ws_romania_57854", 
    "ws_romania_pp", 
    "ws_romania_stg", 
    "ws_cc_id_dev",
    "ws_cc_id_prod",
    "ws_cc_id_qa",
    "pmi_indonesia"
  ];
  
  if(data.country === "PL" || data.country === "IN" || data.country === "FR" || data.country === "IT"){
    sitesThatDoNotGetScan.push("superawesome3ec5241", "superawesomec1ed882");
  }

  const isNotScan = sitesThatDoNotGetScan.indexOf(settings.site_name) > -1;

  const scanPath = (!isNotScan ? '../snap/scan/scansidestart/dl/front' : '../snap/upload/dl');
  const scanPathPassport = (!isNotScan ? '../snap/scan/scansidestart/passport/front' : '../snap/upload/passport');

  const isPassportOption = settings.isPassportOption;

  let logo_url;
  if(settings.style){
    logo_url = (settings.style[0].logo_url ? settings.style[0].logo_url : null);
  }else{
    logo_url = null;
  }


      let nlText;

      if(data.language === "en" || data.language === ""){
        nlText = 'If you submit an identity document from the Netherlands, make sure that your citizen service number (BSN) is not visible in the photo.';
      }else if(data.language === "fr"){
        nlText = "Si vous soumettez une pièce d'identité des Pays-Bas, assurez-vous que votre numéro de service citoyen (BSN) n'est pas visible sur la photo.";
      }else if(data.language === "de"){
        nlText = 'Wenn Sie einen Ausweis aus den Niederlanden einreichen, achten Sie darauf, dass Ihre Bürgerservicenummer (BSN) nicht auf dem Foto zu sehen ist.';
      }else if(data.language === "nl"){
        nlText = 'Als u een identiteitsbewijs uit Nederland overlegt, zorg er dan voor dat uw burgerservicenummer (BSN) niet zichtbaar is op de foto.';
      }else{
        nlText = 'Als u een identiteitsbewijs uit Nederland overlegt, zorg er dan voor dat uw burgerservicenummer (BSN) niet zichtbaar is op de foto.';
      }


      return (  <div className="content">
      {this.renderRedirect()}
          <Container >

          {data.country === "NL" &&
            <p className='text-center p-1 border-danger text-white' style={{backgroundColor: 'rgba(255, 0, 0, 0.7)', fontSize:'12px'}}>{nlText}</p>
          }

          {settings.site_name.search(/superawesome/i) !== -1 &&
            <IntroModal text={settings.text}/>
          }
          

          <Row className="text-left mb-0 pb-0">
            <Col>
              <h3 className="font-weight-bold">{settings.text.mobileIntro.title}</h3>
              <p className="small muted">{settings.text.mobileIntro.subTitle}</p>
            </Col>
          </Row>

          {logo_url &&

              <div style={{minHeight:"175px", height:"175px"}} className="d-flex justify-content-center text-center">

                  <img alt="logo" className="align-self-center" src={logo_url} style={{maxWidth:"175px", maxHeight:"175px"}}/>

              </div>

          }
          {!logo_url &&
          <Row className="text-center mb-0 pb-0 mt-0 pt-0">
            <Col>
            <Lottie
              options={{
                animationData: idScan,
              }}
              width={175}
              ariaRole={"img"}
              isStopped={this.state.isStopped}
              title={"id-scan"}
            />
            </Col>
          </Row>
        }

          <Row className="text-left mb-0 pb-0 font-weight-bold">
            <Col>
              <p>{settings.text.mobileIntro.explanation}</p>
            </Col>
          </Row>

          <Row className="text-left h6 text-muted pl-3 mt-0 pt-0">
            <Col sm={12} md={4} className="mt-1">
              <p ><i className="fal fa-check "></i><span className="ml-3 ">{settings.text.mobileIntro.explanation_1}</span></p>
            </Col>
            <Col sm={12} md={4} className="mt-1">
              <p ><i className="fal fa-check "></i><span className="ml-3 ">{settings.text.mobileIntro.explanation_2}</span></p>
            </Col>
            <Col sm={12} md={4} className="mt-1">
              <p ><i className="fal fa-check"></i><span className="ml-3 ">{settings.text.mobileIntro.explanation_3}</span></p>
            </Col>
          </Row>

          {settings.isDocTypeIntro !== true &&
          <Row className="mt-3">
            <Col sm={12} md={12}>
              <button
                type="button"
                value="email"
                className="veratad-primary mb-4"
                onClick={() => nextPath(history, scanPath) }
              >
              {settings.text.mobileIntro.actionButton}
              </button>
            </Col>
            {isPassportOption &&
            <Col sm={12} md={12} className="text-center mb-4">
                <button
                  type="button"
                  value="device"
                  className="veratad-secondary"
                  onClick={() => nextPath(history, scanPathPassport) }
                >
                {settings.text.mobileIntro.secondaryButton}
                </button>
            </Col>
            }
          </Row>
        }

        {settings.isDocTypeIntro === true &&
        <Row className="mt-3">
          <Col>
            <button
              type="button"
              value="email"
              className="veratad-primary mb-4"
              onClick={() => nextPath(history, './snap/documentTypeIntro/mobile') }
            >
            {settings.text.mobileIntro.button_when_doc_type_is_active}
            </button>
          </Col>
        </Row>
      }

        </Container>
      </div>
    );
  }
}

export default withRouter (Intro);