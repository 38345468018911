import React, { useEffect } from 'react';
import FaceCapture from "@getyoti/react-face-capture"
import "@getyoti/react-face-capture/index.css"

function App(props) {


  const handleResult = (result, secure) => {
    props.onData(result, secure);
  }

  const handleException = (excpt) => {
    props.onError(excpt);
  }

  useEffect(() => {
    console.log('Yoti Mounted');
  }, []);

  const onSuccess = ({ img, secure }) => handleResult(img, secure);
  const onError = (error) => handleException(error);

  return <FaceCapture
    onSuccess={onSuccess}
    onError={onError}
    secure={props.isSecure}
    faceCaptureAssetsRootUrl={'/assets/face-capture/'} />;
}

export default App;
