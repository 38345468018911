import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {back} from './Utils.js';
import {withRouter} from 'react-router-dom';
import Svg from './Svg.js';
import FrontDl from './assets/dl_front.svg';

import './App.css';

class Close extends Component {

  constructor(props) {

    super(props);
    this.state = {
      radio: '',
      comment: '',
      chars_left: 160,
      max_char: 160
    };

    this.iAmFinished = this.iAmFinished.bind(this);
    this.handleRadioSelect = this.handleRadioSelect.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleWordCount = this.handleWordCount.bind(this);
  }

  iAmFinished(){

    const data = {'reason': this.state.radio, 'comment': this.state.comment, 'request_id': this.props.settings.request_id};

    fetch( process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/storeCloseReason.php",
  {
      method: "POST",
      body: JSON.stringify(data)
  })
  .then(function(res){ return res.json(); })
  .then(function(data){

    window.top.postMessage('CLOSE', '*');

  })
  .catch(function() { window.top.postMessage('CLOSE', '*'); })

  }

  handleRadioSelect(event){

   this.setState({
       radio: event.target.id
    })

}

  handleComment(event){

   this.setState({
       comment: event.target.value
    })

  }

  handleWordCount (event) {
      const charCount = event.target.value.length;
      const maxChar = this.state.max_char;
      const charLength = maxChar - charCount;
      this.setState({ chars_left: charLength });
  }


  render() {
    const { history, settings } = this.props;

    const icon_color = settings.style[0].settings.icons;

    const radios = {
      DO_NOT_WANT: settings.text.close.choice_1,
      DO_NOT_UNDERSTAND: settings.text.close.choice_2,
      OTHER: settings.text.close.choice_3
    };

    return (
      <div>

    <div className="content">
      <Container className="h-100 mt-5">


      <Row className="text-center mt-3">
      <Col>
        <Svg src={FrontDl} color={icon_color} />
        </Col>
      </Row>

      <Row className="text-center mt-3">

        <Col>
          <h2 className="font-weight-bold">{settings.text.close.title}</h2>
        </Col>
      </Row>



      <Row>
      <Col>
      <p className="font-weight-bold text-center small">{settings.text.close.subTitle}:</p>
      </Col>
      </Row>

      <Row >
      <Col className="text-left small d-flex justify-content-center">

      <Form>

      {
        Object.entries(radios).map(([k, value]) => {
        return(
          <div key={`${k}`} onChange={this.handleRadioSelect }>
              <Form.Check
              className="mb-3"
                custom
                type="radio"
                name="closed"
                id={`${k}`}
                label={`${value}`}
              />
            </div>
        )

        })
      }
      <Form.Group  controlId="exampleForm.ControlTextarea1" onChange={this.handleWordCount}>
        <Form.Label>{settings.text.close.subTitle}:</Form.Label>
        <Form.Control  maxLength="160" as="textarea" rows={2} onChange={this.handleComment }/>
      </Form.Group>
      <p>{settings.text.close.counterPre} {this.state.chars_left} {settings.text.close.counterPost}</p>
  </Form>


      </Col>
      </Row>

      <Row className="mt-4">
        <Col>
        <button
          type="button"
          value="email"
          className="veratad-secondary mb-4 text-center"
          onClick={() => this.iAmFinished() }
        >
        {settings.text.close.secondaryButton}
        </button>
        </Col>

        <Col>
            <button
              type="button"
              value="device"
              className="veratad-primary mb-4"
              onClick={() => back(history) }
            >
            {settings.text.close.actionButton}
            </button>
        </Col>
        </Row>

      </Container>
    </div>
    </div>
    );
  }
}

export default withRouter (Close);
