import AWS from 'aws-sdk/dist/aws-sdk-react-native';
import FrontDl from '../assets/dl_front.svg';
import BackDl from '../assets/dl_back.svg';


export const displayError = (value, request, setState, errorCount, subtitle) => {

  if(request === "DL_FRONT"){
    setState({errorCount: errorCount + 1, preview_front: FrontDl, imageLoadingFront: false, imageLoadedFront: false, error:true, errorTitle: value, errorSubTitle: subtitle});
  }else if(request === "PDF417_BARCODE"){
    setState({errorCount: errorCount + 1, preview_back: BackDl, imageLoadingBack: false, imageLoadedBack: false, error:true, errorTitle: value, errorSubTitle: subtitle});
  }else{
    setState({errorCount: errorCount + 1, preview_front: FrontDl, imageLoadingFront: false, imageLoadedFront: false, error:true, errorTitle: value, errorSubTitle: subtitle});
    setState({errorCount: errorCount + 1, preview_back: BackDl, imageLoadingBack: false, imageLoadedBack: false, error:true, errorTitle: value, errorSubTitle: subtitle});
  }
}

export const acceptImage = (result, setState, request_id, international, currentScan) => {


  let side = '';
  let type = '';

  if(international){

    if(currentScan === "front"){
      side = "front";
      type = "front";
    }else if (currentScan === "back"){
      side = "back";
      type = "back";
    }else if(currentScan === "passport"){
      side = "passport";
      type = "passport";
    }else{
      side = "selfie";
      type = "selfie";
    }

  }else{


    if(result.request === "DL_FRONT"){
      side = "front";
      type = "front";
    }else if (result.request === "PDF417_BARCODE"){
      side = "back";
      type = "back";
    }else if(result.request === "PASSPORT"){
      side = "passport";
      type = "passport";
    }else{
      side = "selfie";
      type = "selfie";
    }
  }


  S3UploadSnap(side, setState, result.response.imageData, request_id, function() {

    if(type === "front"){
      setState({isLoading: false, preview_front: result.response.imageData, imageLoadingFront: false, imageLoadedFront: true, error:false})
    }else if (type === "back"){
      setState({isLoading: false, preview_back: result.response.imageData, imageLoadingBack: false, imageLoadedBack: true, error:false})
    }else if(type === "passport"){
      setState({isLoading: false, preview_passport: result.response.imageData, imageLoadingPassport: false, imageLoadedPassport: true, error:false})
    }else{
      setState({isLoading: false, preview_selfie: result.response.imageData, imageLoadingFront: false, imageLoadedFront: true, error:false})
    }

  });

}

const S3UploadSnap = (id, setState, imageUri, request_id, _callback) => {

  if(id === "back"){
    setState({imageLoadingBack: true})
  }else if (id === "front"){
    setState({imageLoadingFront: true})
  }else{
    setState({imageLoadingPassport: true})
  }

  var albumBucketName = "dcamsv3";
  var bucketRegion = "us-east-2";
  var IdentityPoolId = "us-east-2:d6381cdb-1612-4dd1-ad4a-c8b28de1bd8b";
  var albumName = "upload";

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId
    })
  });

  if(id === "passport"){
    id = "front";
  }

  var name = "dl_" + id;
  var fileName = request_id + "_" + name + ".jpeg";
  var file_blob = dataURItoBlob(imageUri);


  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file_blob,
      ACL: ""
    }
  });

  var promise = upload.promise();

  promise.then(
    function(data) {

      console.log('here is the data from upload', data); 

      _callback();

    },
    function(err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  );

}

const dataURItoBlob = (dataURI) => {

  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}
