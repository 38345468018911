import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import faceId from '../assets/face-id-scan.json';
import Loading from './Loading';
import '../App.css';
import '../../App.css'; 

const Intro = ({ onGetStartedClick }) => {

  const [loading, setLoading] = useState(false);

  const handleGetStartedClick = () => {
    setLoading(true)
    onGetStartedClick('User clicked the "Get Started" button');
  };

  if (loading) {
    return (<Loading text={"Preparing..."} />);
  }

  return (
    <div>
      <Row className="mt-n5">
        <Col className="text-center">
          <div className="img-fluid mx-auto d-block">
            <Lottie
              animationData={faceId}
              loop
              play
              style={{ height: 150 }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-0">
        <Col className="text-center">
          <h2>Age Confirmation</h2>
          <p className='mt-4'>Our facial scanning technology ensures quick and accurate age estimation without the need for personal information.</p>
        </Col>
      </Row>
  
      <Row className="mt-3">
        <Col className="text-center">
          <div className="table-wrapper">
            <table className="table-icon-text">
              <tbody>
                {[
                  {text: 'You will be asked to allow camera access', icon: 'fa-video'},
                  {text: 'This process is designed with your privacy in mind', icon: 'fa-lock'},
                  {text: 'Make sure you are in a well-lit space', icon: 'fa-lightbulb'},
                ].map((item, index) => (
                  <tr className="text-left " key={index}>
                    <td>
                      <div className="circle-icon mr-3 ">
                        <i className={`fas ${item.icon}`}></i>
                      </div>
                    </td>
                    <td >
                      <span className="mb-0 small">{item.text}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
  
      <div className="mt-3 text-center">
        <button
          className="veratad-primary"
          style={{ marginBottom: "20px", width: "95%" }}
          onClick={handleGetStartedClick}
        >
          Get Started
        </button>
      </div>
    </div>
  );
  
  
  
  
  
  }
  export default Intro; 
