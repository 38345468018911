import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import LoadingIndicator from '../LoadingIndicator.js';
import {withRouter} from 'react-router-dom';
import {nextPath, close, inIframe} from '../Utils.js'
import '../App.css';

class TryAgain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fn: this.props.data.fn,
      ln: this.props.data.ln,
      dob: this.props.data.dob,
      ssn: "",
      verifying: false,
      dob_input: this.props.data.dob,
      icon: null,
      header: null,
      sub: null,
      verified: false,
    };

    this.verify = this.verify.bind(this)
    this.ratherID = this.ratherID.bind(this)
    this.lnChange = this.lnChange.bind(this)
    this.fnChange = this.fnChange.bind(this)
    this.ssnChange = this.ssnChange.bind(this)
    this.dobInputChange = this.dobInputChange.bind(this)
  }

  lnChange(e){
    this.setState({ln: e.target.value})
  }

  fnChange(e){
    this.setState({fn: e.target.value})
  }

  ssnChange(e){
    this.setState({ssn: e.target.value})
  }

  dobInputChange(e){
    this.setState({dob_input: e.target.value})
  }

  reformatDOB(dob){
    return dob.replace(/[^a-zA-Z0-9]/g, '');
  }

  verify(){

    const { history, settings } = this.props;
    const that = this;

    //that.props.data.dob = this.state.year + this.state.month + this.state.day;

    //if(that.props.data.dob.length != 8){
    //  alert("DOB is a mandatory field")
  //  }

    that.setState({verifying: true})

    fetch(process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/agematch",
  {
      method: "POST",
      body: JSON.stringify(that.props.data)
  })
  .then(function(res){ return res.json(); })
  .then(function(data){
    that.setState({verifying: false})
    if(data.action === "PASS"){
      that.setState({verified: true, header: data.title, sub: data.subtitle, icon: data.icon})
      window.top.postMessage('PASS', '*');
    }else{
      settings.isAgeMatch = false;
      nextPath(history, '/');
    }
    //that.setState({running: false, verificationComplete: true, response: data, query: data.query, http_code: data.http_code, time: data.time, resource: data.resource});
  })
  .catch(function() { alert("Something Went Wrong! Please try again.")})

 }

 componentDidMount() {
   this.changeDateFormat();
 }

 changeDateFormat() {

     let dob = this.props.data.dob;
     let year = dob.substring(0, 4);
     let month = dob.substring(4, 6);
     let day =  dob.substring(6, 8);

     this.setState({dob_input: year + "-" + month + "-" + day});

}

ratherID(){
  const { history, settings } = this.props;
  settings.isAgeMatch = false;
  nextPath(history, '/')
}



render() {
  const { data, history, settings } = this.props;
  const { verifying } = this.state;

  let isiFrame = inIframe();

    data.ln = this.state.ln;
    data.fn = this.state.fn;
    data.ssn = this.state.ssn;
    data.dob = this.reformatDOB(this.state.dob_input);

      return (  <div className="content">
      {!this.state.verified &&

        <Container >

        <Row className="text-center">
          <Col>
            <h4  className="font-weight-bold">Oops! Let's try that again.</h4>
          </Col>
        </Row>


      <Row className="text-center">
        <Col>
        <p className="text-muted small mt-0 pt-0">Please check your details and try again. Using your legal name and accurate details gives you the best chance for success. </p>
        </Col>
      </Row>

      <Row className="text-left mt-3">

        <Col>

          <Form.Group controlId="forfn">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" value={this.state.fn}  onChange={this.fnChange}/>
          </Form.Group>


        </Col>

        <Col>

          <Form.Group controlId="forln">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" value={this.state.ln} onChange={this.lnChange}/>
          </Form.Group>


        </Col>



      </Row>


        <Row className="text-left mt-3">

          <Col>

            <Form.Group controlId="forDateofBirth">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control type="date" value={this.state.dob_input} onChange={this.dobInputChange}/>
            </Form.Group>


          </Col>

          <Col>

            <Form.Group controlId="forSSN">
              <Form.Label>Last 4 SSN (optional)</Form.Label>
              <Form.Control type="text" value={this.state.ssn}  onChange={this.ssnChange}/>
              <Form.Text className="text-muted">
                This is never stored and only used for verification.
            </Form.Text>
            </Form.Group>


          </Col>



        </Row>

        <Row className="mt-4 text-center">

          <Col>
            {!verifying &&
              <button
                type="button"
                value="device"
                className="veratad-primary mb-4"
                onClick={this.verify}
              >
              Verify Me
              </button>
              }

              {verifying &&
                <LoadingIndicator height={50} width={100} />
              }

          </Col>
        </Row>
        {!verifying &&
        <Row className="text-center mt-4" >
          <Col>
          <button
            type="button"
            className="no-style-button small text-muted"
            style={{cursor:'pointer'}}
            onClick={this.ratherID}
          >
        I would rather verify with an ID Document. Click here.
          </button>
        </Col>
      </Row>
      }


      </Container>

      }


        {this.state.verified &&
          <Container className="h-100">
            <Row className="text-center h-100 justify-content-center align-items-center">
              <Col>
          <Row className="text-center">
            <Col>
              <i className={this.state.icon + "result-icon"}></i>
            </Col>
          </Row>

          <Row className="text-center mt-3">
            <Col>
              <h2 className="font-weight-bold">{this.state.header}</h2>
            </Col>
          </Row>

          <Row className="text-center mt-0 mb-3">
            <Col>
              <p>{this.state.sub}</p>
            </Col>
          </Row>

          {isiFrame &&
            <Row className="text-center mt-5">
              <Col>
                    <button
                      type="button"
                      className="veratad-primary w-50"
                      onClick={() => close(history, settings) }
                      >
                      Close
                    </button>
                </Col>
            </Row>
          }

          </Col>
      </Row>
          </Container>
        }
      </div>
    );
  }
}


export default withRouter (TryAgain);
