import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../../../LoadingIndicator.js";
import Svg from "../../../Svg.js";

import "../../../App.css";

class Dl extends Component {
  render() {
    const {
      uploadHandleClick,
      imageLoadedFront,
      imageLoadedBack,
      imageLoadingFront,
      imageLoadingBack,
      preview_front,
      preview_back,
      settings,
    } = this.props;

    const loading_color = settings.style[0].settings.loading;
    const icon_color = settings.style[0].settings.icons;

    const front_image = imageLoadedFront ? (
      <img
        src={preview_front}
        alt="Upload Example Front"
        width={75}
        height={75}
      />
    ) : (
      <Svg src={preview_front} color={icon_color} />
    );
    const back_image = imageLoadedBack ? (
      <img
        src={preview_back}
        alt="Upload Example Back"
        width={75}
        height={75}
      />
    ) : (
      <Svg src={preview_back} color={icon_color} />
    );

    const display_front = imageLoadingFront ? (
      <Loading height={75} width={75} color={loading_color} />
    ) : (
      front_image
    );
    const display_back = imageLoadingBack ? (
      <Loading height={75} width={75} color={loading_color} />
    ) : (
      back_image
    );

    let currentlyLoading;
    if (imageLoadingFront || imageLoadingBack) {
      currentlyLoading = true;
    } else {
      currentlyLoading = false;
    }

    return (
      <div>
        <div
          id="front"
          style={{ cursor: "pointer" }}
          onClick={!currentlyLoading ? uploadHandleClick : null}
        >
          <Row className="dropZone justify-content-center align-items-center text-center">
            <Col>
              <div className="d-flex">
                <div>{display_front}</div>
                <div className="ml-auto align-self-end text-left">
                  {!imageLoadedFront && (
                    <div className="ml-3">
                      <p className="pb-0 mb-0 font-weight-bold">
                        {settings.text.upload.frontUploadTitle}
                      </p>
                      <p className="pt-0 mt-0 small text-muted">
                        {settings.text.upload.uploadSubTitle}
                      </p>
                    </div>
                  )}

                  {imageLoadedFront && (
                    <div>
                      <p className="pb-0 mb-0 font-weight-bold">
                        {settings.text.upload.frontUploadedTitle}
                      </p>
                      <p className=" pt-0 mt-0 text-danger small">
                        <i className="far fa-times"></i>
                        <span className="ml-1">
                          {settings.text.upload.uploadedSubTitle}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {!settings.isOneSided && (
          <div
            id="back"
            className="mt-3"
            style={{ cursor: "pointer" }}
            onClick={!currentlyLoading ? uploadHandleClick : null}
          >
            <Row className="dropZone justify-content-center align-items-center text-center">
              <Col>
                <div className="d-flex">
                  <div>{display_back}</div>
                  <div className="ml-auto align-self-end text-left">
                    {!imageLoadedBack && (
                      <div className="ml-3">
                        <p className="pb-0 mb-0 font-weight-bold">
                          {settings.text.upload.backUploadTitle}
                        </p>
                        <p className="pt-0 mt-0 small text-muted">
                          {settings.text.upload.uploadSubTitle}
                        </p>
                      </div>
                    )}

                    {imageLoadedBack && (
                      <div>
                        <p className="pb-0 mb-0 font-weight-bold">
                          {settings.text.upload.backUploadedTitle}
                        </p>
                        <p className=" pt-0 mt-0 text-danger small">
                          <i className="far fa-times"></i>
                          <span className="ml-1">
                            {settings.text.upload.uploadedSubTitle}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default Dl;
