import React, { Component } from "react";
import { Modal } from 'react-bootstrap'
import Svg from '../../../../Svg.js';
import Stars from '../../../../assets/five_stars.svg';

class IntroModal extends Component{

    constructor(){
        super();
        this.state = {
            showHide : true
        }
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }

    render(){

        const { text } = this.props;

        return(
            <div>
                

                <Modal 
                centered 
                backdrop="static"
                keyboard={false}
                show={this.state.showHide}
                >
                    <div className="text-center mt-2 p-3">

                        <div className="h4 font-weight-bold">
                                {text.introModal.title}
                            </div>
                            

                            

                        <div className="mt-4">
                            <Svg src={Stars} />
                        </div>

                        

                        <div className="mt-3">
                        {text.introModal.text}
                        </div>
                        
                    </div>
                       
                        <div className="text-center  p-3">
               
                                <button
                            type="button"
                            className="veratad-primary mb-3"
                            onClick={() => this.handleModalShowHide()}
                                >
                                {text.introModal.button}
                            </button>

                    </div>
                </Modal>

            </div>
        )
    }
    
}

export default IntroModal;