import React, { Component } from "react";

import '../../../App.css';

class Error extends Component {

  render() {

    const { errorTitle, errorSubTitle } = this.props;

    return (
      <div className="d-flex align-items-center shadow border-left border-danger rounded mb-3">
      <div className="pl-2"><i className="fal fa-exclamation-circle text-danger alert-icon"></i></div>
        <div className="ml-4">
          <p className="font-weight-bold mb-0 pb-0 pt-2 pr-2">{errorTitle}</p>
          <p className="small mt-0 pt-0 pr-2">{errorSubTitle}</p>
        </div>
      </div>
    );
  }
}

export default Error;
